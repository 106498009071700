import React, {Component} from 'react';
import {connect} from 'react-redux';
import Card from './card';
import styled from 'styled-components';
import {
    loadTokenAbi,
    loadTokenDetail,
    loadTokenTransfers
} from '../../actions/token';
import TronWeb from "tronweb";
import {FULL_HOST} from "../../config/config";
import {updatePageTransfer, updatePageTransferLimit} from "../../actions/transfer";

const Container = styled.div`
  margin: 5px;
`;
const CardTitle = styled.div`
  border-bottom: 5px solid #C23631;
  font-size: 20px;
  text-align: left;
`;
const CardDes = styled.div`
  width: 100%;
  background-color: #F3F3F3;
  flex-direction: column;
  display: flex;
  padding-left: 30px;
  padding-right: 30px;
  text-align: left;
  padding-bottom: 5px;
  padding-top: 5px;
`;
const StyledName = styled.span`
  font-size: 25px;
  font-weight: 500;
`;
const StyledDes = styled.span`
  font-size: 15px;
  font-weight: 300;
`;


class TokenDetails extends Component {

    componentDidMount() {
        this.props.loadTokenDetail(this.props.match.params.id);
        const {tokenTransfer} = this.props;
        console.log(this.props);
        this.props.loadTokenTransfers(this.props.match.params.id, tokenTransfer.transferPage.total_items, tokenTransfer.transferPage.current_page);
        this.props.loadTokenAbi(this.props.match.params.id);
    }


    render() {
        return (
            <Container>
                <CardTitle>
                    <h3>{this.props.token.abbr}</h3>
                </CardTitle>
                <CardDes>
                    <StyledName>{this.props.token.name}</StyledName>
                    <StyledDes>{this.props.token.description}</StyledDes>
                </CardDes>
                <br/>
                <Card/>
            </Container>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        token: state.token,
        tokenTransfer: state.tokenTransfer,
        abi:state.tokenAbi,

    };
};
const mapDispatchToProps = dispatch => {
    return {
        loadTokenDetail: (id) => {
            dispatch(loadTokenDetail(id));
        },
        loadTokenTransfers: (id, limit, start) => {
            dispatch(loadTokenTransfers(id, limit, start));
        },
        loadTokenAbi: (id) => {
            dispatch(loadTokenAbi(id));
        },
    };

};
export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(TokenDetails);
