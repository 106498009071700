import fetch from 'cross-fetch';

import { FULL_HOST } from '../config/config';
export const NODES_INIT = 'NODES_INIT';
export const NODES_UPDATE = 'NODES_UPDATE';


export function initNodes() {
	return {
		type: NODES_INIT,
	}
}
export function updateNodes(nodes) {
	return {
		type: NODES_UPDATE,
		payload: nodes
	}
}

export function loadNodes() {
	return (dispatch) => {
		dispatch(initNodes());
		fetch(`${FULL_HOST}/wallet/listnodes`, {
			method: 'GET',
			mode: 'cors',
		}).then(res => res.json()).then((res) => {
			dispatch(updateNodes(res.nodes));
		}).catch(err => {
			console.log(err);
		})
	}
}