import fetch from 'cross-fetch';
import {notification} from 'antd';
import {API_ADDR, FULL_HOST, API_ADDR_NEW, WSS_ADDR_NEW, API_ADDR_NEW1} from '../config/config';
const mqtt = require('mqtt');
export const RECENT_BLOCK_UPDATE = 'RECENT_BLOCK_UPDATE';
export const RECENT_BLOCK_INIT = 'RECENT_BLOCK_INIT';
const client = mqtt.connect(`${WSS_ADDR_NEW}`, {
    username: 'frontend',
    password: 'DDgg8WHv8YLdp9t',
});
client.on('connect', function () {
    console.log('connected');
    client.subscribe('acscan/blocks', function (err){
        if (err){
            console.log(err)
        }
    })
})
export function initRecentBlock() {
    return {
        type: RECENT_BLOCK_INIT,
    }
}

export function updateRecentBlock(blocks) {
    return {
        type: RECENT_BLOCK_UPDATE,
        payload: blocks
    }
}

export function loadRecentBlocks() {
    return (dispatch) => {
        dispatch(initRecentBlock());
        fetch(`${API_ADDR_NEW}/blocks/recent`)
            .then(res => res.json())
            .then((data) => {
                dispatch(updateRecentBlock(data));
            }).then(() => {
            client.on('message', function (topic, message) {
                const blocks = JSON.parse(message.toString());
                dispatch(updateRecentBlock(blocks))
            })
        }).catch(err => {
            console.log(err);
        })
    }
}


export const SYSTEM_STATE_INIT = 'SYSTEM_STATE_INIT';
export const SYSTEM_STATE_UPDATE = 'SYSTEM_STATE_UPDATE';

export function initSystemState() {
    return {
        type: SYSTEM_STATE_INIT
    }
}

export function updateSystemState(systemState) {
    return {
        type: SYSTEM_STATE_UPDATE,
        payload: systemState,
    }
}

export function loadSystemState() {

    return dispatch => {
        dispatch(initSystemState());
        fetch(`${FULL_HOST}/wallet/listnodes`)
            .then(res => res.json()).then((res) => {
            client.on('message', function (topic, message) {
                const blocks = JSON.parse(message.toString());
                dispatch(updateSystemState({
                    block_height: blocks[0].num,
                    total_nodes: res.nodes.length,
                    asset_num: 0
                }));
            })
        }).catch(err => {
            console.log(err);
        })
    }
}


export const SEARCH_REQUESTING = 'SEARCH_REQUESTING';
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS';

export function requestSearch() {
    return {
        type: SEARCH_REQUESTING,
    }
}

export function updateSearchResult(objType, key) {
    return {
        type: SEARCH_SUCCESS,
        payload: {
            type: objType,
            key: key,
        },
    }
}

export function search(key, onsuccess) {
    return dispatch => {
        dispatch(requestSearch());
        fetch(`${API_ADDR_NEW}/home/searchAll/?data=${key}`
        //     , {
        //     method: 'GET',
        //     headers: {
        //         'Content-Type': 'application/json',
        //     },
        //     mode: 'no-cors',
        // }
        ).then(res => res.json()).then((res) => {
            dispatch(updateSearchResult(res, key));
            onsuccess();
        }).catch(err => {
            console.log(err);
        })
    }
}



