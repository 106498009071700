import {
	NODES_INIT,
	NODES_UPDATE,
} from '../actions/nodes';

const initNodes = {
	nodes: [],
}
export function nodesReducer(state = initNodes, action) {
	switch (action.type) {
		case NODES_UPDATE:
			return { ...state, nodes: action.payload };
		case NODES_INIT:
			return initNodes;
		default:
			return state;
	}
}
