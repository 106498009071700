import {
	TRANSFER_INIT,
	TRANSFER_UPDATE,
	PAGE_TRANSFER_INIT,
	PAGE_TRANSFER_UPDATE,
	PAGE_TRANSFER_TOTAL_UPDATE,
	PAGE_TRANSFER_LIMIT_UPDATE
} from '../actions/transfer';

const initTransfer = {
	transfer:[],
	transferPage:{
		start_page:1,
		start_item:0,
		page_limit:10,
		total_items:10000,
		current_page:1
	}
};

export function transferReducer(state = initTransfer, action) {
	switch (action.type) {
		case TRANSFER_UPDATE:
			state = {...state, transfer: action.payload};
			break;
		case TRANSFER_INIT:
			break;
		case PAGE_TRANSFER_UPDATE:
			state.transferPage.current_page = action.payload;
			state.transferPage.start_item += (state.transferPage.current_page-state.transferPage.start_page)*state.transferPage.page_limit;
			state.transferPage.start_page = action.payload;
			break;
		case PAGE_TRANSFER_INIT:
			break;
		case PAGE_TRANSFER_TOTAL_UPDATE:
			state.transferPage.total_items = action.payload;
			break;
		case PAGE_TRANSFER_LIMIT_UPDATE:
			state.transferPage.page_limit = action.payload;
			state.transferPage.start_item = (state.transferPage.current_page-1)*state.transferPage.page_limit;
			break;
		default:
			break;
	}
	return state;
}
