import React, {Component} from "react";
import {connect} from "react-redux";
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {currencyFormat, toDateTime, toDateTimeEndTime} from '../../utils/utils';
import {ArrowRightOutlined, SettingOutlined} from '@ant-design/icons';
import {Collapse, Form, List, Skeleton} from 'antd';
import {Tabs, Card, Row, Col, Space, Button, Input, Modal} from 'antd';
import {FULL_HOST} from "../../config/config";
import TronWeb from "tronweb";
import TransfersTab from "./transfers-tab";
import {updateTabTransfer, updateTabTransferLimit} from "../../actions/token";
import ReadContractTab from "./read-contract-tab";
import WriteContractTab from "./write-contract-tab";
// import {loadTokenTransfers} from "../../actions/token";

const {TabPane} = Tabs;
const {TextArea} = Input;
const {Panel} = Collapse;
const {Search} = Input;
const operations = <Search
    placeholder="Filter Address/Hash/Token"
    enterButton="SEARCH"
    // onSearch={this.onSearch}
    // loading={SEARCH_REQUESTING === this.props.state}
    // ref={this.searchBoxRef}
/>;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const CardContent = styled.div`
  max-height: 90px;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-direction: row;
  border-bottom: 1px solid #EEEEEE;
  padding-top: 10px;
  padding-bottom: 10px;
`;
const Content = styled.div`
  padding-left: 25px;
  padding-right: 25px;
  background-color: #ffffff;
  width: 100%;
`;
const RowTitle = styled.div`
  min-width:25%;
  text-align: left;
  height: 30px;
`;
const StyledTitle = styled.span`
  min-width:auto;
  font-weight: 500;
  color: #666;

`;
const RowValue = styled.div`
  text-align: left;
  overflow: auto;
`;
const RedText = styled.span`
  color: #E50915;
`;
const StyledLink = styled(Link)`
  &:link, &:visited {
    color: #c23631;
  }
`;
const LinkA = styled.a`
  color: #c23631;
`;

const StyledTabs = styled(Tabs)`
  width: 100%;
  background-color: #F3F3F3;
  flex-direction: column;
  display: flex;
`;
const CardStyled = styled(Card)`
  width: auto;
  background-color: #F3F3F3;
  flex-direction: column;
  display: flex;
  //padding-left: 30px;
  //padding-right: 30px;
  text-align: left;
  padding-bottom: 5px;
  //padding-top: 5px;
`;
const WrapperCustom = styled.div`
  padding-left: 20px;
  text-align: left;
  font-size: 20px;
  //text-transform: uppercase;
`;

const StyledRow = styled(Row)`
  width: 101%;
  background-color: #F3F3F3;
  flex-direction: row;
  display: flex;
`;



const ButtonModal =   (props) => {
    const [visible, setVisible] = React.useState(false);
    const [confirmLoading, setConfirmLoading] = React.useState(false);
    // const [modalText, setModalText] = React.useState("Content of the modal");
    const [modalText, setModalText] = React.useState(" ");



    const getModaContent = async (method) => {
        const tronWeb = new TronWeb({fullHost: FULL_HOST,})
        tronWeb.setAddress(this.props.token.id);
        const {abi} = await tronWeb.trx.getContract(this.props.token.id);
        // console.log(JSON.stringify(abi));
        const contract = tronWeb.contract(abi.entrys, this.props.token.id);
        if(method === "name"){
            const name =  await contract.methods.name().call();
            return name;
        }

    }
    const showModal =  () => {
        getModaContent(props.method).then(r => setModalText(r) );

        setVisible(true);


    };

    const handleOk = () => {
        setModalText("The modal will be closed after two seconds");
        setConfirmLoading(true);
        setTimeout(() => {
            setVisible(false);
            setConfirmLoading(false);
        }, 2000);
    };

    const handleCancel = () => {
        console.log("Clicked cancel button");
        setVisible(false);
    };

    return (



        <>
            <Button type="primary" onClick={showModal}>
                {props.buttonText}
            </Button>
            <Modal
                title="Select Connection Method"
                visible={visible}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
                width={800}
                height={600}
            >
                <p>{modalText}</p>
            </Modal>
        </>
    );
};




class CardInfo extends Component {


    render() {


        const contentLeft = [

            {
                title: 'Issuer',
                value: <StyledLink
                    to={`/account/${this.props.token.owner_address}`}>{this.props.token.owner_address}</StyledLink>
            },
            {title: 'Total supply', value: <RedText>{currencyFormat(this.props.token.total_supply)}</RedText>},
            // {
            //     title: 'Price',
            //     value: <span> {currencyFormat(this.props.token.num)} {this.props.token.abbr}
            //         <ArrowRightOutlined/> {currencyFormat(this.props.token.trx_num)} ACG</span>
            // },
            // { title: 'decimals',  value: <RedText>{currencyFormat(this.props.token.precision)}</RedText>},
            // { title: 'Start time',  value: toDateTime(this.props.token.start_time)},
            // { title: 'End time',  value:   toDateTimeEndTime(this.props.token.end_time)},
            // { title: 'Offical Website',  value: <LinkA href={this.props.token.url} target="_blank">{this.props.token.url}</LinkA>},
        ];

        const contentRight = [
            // { title: 'Id',  value: this.props.token.id},
            // { title: 'Issuer',  value: <StyledLink to={`/account/${this.props.token.owner_address}`}>{this.props.token.owner_address}</StyledLink>},,
            // { title: 'Total supply',  value: <RedText>{currencyFormat(this.props.token.total_supply)}</RedText>},
            {title: 'Decimals', value: <RedText>{currencyFormat(this.props.token.decimals)}</RedText>},
            { title: 'Price',  value: <span> {currencyFormat(this.props.token.num)}{this.props.token.abbr}<ArrowRightOutlined/>{currencyFormat(this.props.token.trx_num)}ACG</span> },

            // {title: 'Start time', value: toDateTime(this.props.token.start_time)},
            // {title: 'End time', value: toDateTimeEndTime(this.props.token.end_time)},
            // {
            //     title: 'Offical Website',
            //     value: <LinkA href={this.props.token.url} target="_blank">{this.props.token.url}</LinkA>
            // },
        ];

        return (
            <Wrap>
                <Skeleton loading={this.props.token.id === ""}>

                    <StyledRow
                        // gutter={16}
                    >
                        <Col
                            // span={12}
                             xs={24} xl={12}>

                                <CardStyled title="Overview " bordered={true}>
                                    <Content>
                                        <List
                                            itemLayout="vertical"
                                            dataSource={contentLeft}
                                            renderItem={item => (
                                                <List.Item>
                                                    <CardContent key={item}>
                                                        <RowTitle>
                                                            <StyledTitle>
                                                                {item.title}
                                                            </StyledTitle>

                                                        </RowTitle>
                                                        <RowValue> : {item.value}</RowValue>
                                                    </CardContent>
                                                </List.Item>
                                            )}
                                        />

                                    </Content>
                                </CardStyled>


                        </Col>
                        <Col
                            // span={12}
                             xs={24} xl={12}>

                                <CardStyled title="Overview " bordered={true}>
                                    <Content>
                                        <List
                                            itemLayout="vertical"
                                            dataSource={contentRight}
                                            renderItem={item => (
                                                <List.Item>
                                                    <CardContent key={item}>
                                                        <RowTitle>
                                                            <StyledTitle>
                                                                {item.title}
                                                            </StyledTitle>

                                                        </RowTitle>
                                                        <RowValue> : {item.value}</RowValue>
                                                    </CardContent>
                                                </List.Item>
                                            )}
                                        />


                                    </Content>
                                </CardStyled>

                        </Col>
                    </StyledRow>



                    <br/>
                    <StyledTabs tabBarExtraContent={operations}>
                        <TabPane tab="Transfers" key="1">
                            <TransfersTab/>
                        </TabPane>
                        <TabPane tab="Holder" key="2">
                            Content of tab 2
                        </TabPane>
                        <TabPane tab="Info" key="3">
                            Content of tab Info
                        </TabPane>
                        <TabPane tab="Read Contract" key="4">
                            <ReadContractTab />
                        </TabPane>
                        <TabPane tab="Write contract" key="5">
                           <WriteContractTab />
                        </TabPane>

                    </StyledTabs>


                </Skeleton>


            </Wrap>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        token: state.token,
    };
};
const mapDispatchToProps = dispatch => {
    return {

    };
};
export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(CardInfo);
