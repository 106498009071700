import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Table } from "antd";
import { loadAccountDetails } from '../../actions/account';
import { currencyFormat } from '../../utils/utils';
import { FULL_HOST,API_ADDR_NEW } from "../../config/config"
const TronWeb = require('tronweb')
const tronWeb = new TronWeb({
  fullHost: FULL_HOST,
});

const TitleAssetTable = styled.div`
	margin-top: 20px;
	border-bottom: 5px solid #C23631; 
	padding-bottom: 10px;
	padding-top: 10px;
	text-align: left;
	padding-left: 15px;
	background-color: #FAFAFA;
`;
const TableTitle = styled.h3`
	font-size: 20px;
`;
const AssetTable = styled.div`
	margin: 5px;
`;
class AssetsCard extends Component {
	constructor(props) {
		super(props);
		this.state = { 
			data1: [],
			nameC:"",
			balanceC:"" 
		}
	}
	async componentDidMount() {
		this.props.loadAccountDetails(this.props.addr);
		const b =this.props.addr
    	const response = await fetch(`${API_ADDR_NEW}/account?address=${b}`);
		const list = await response.json();
		this.setState({ data1: list.contractList })
		const privateKey =
			'2b04b59ded668aa331d0ce1acff3407b265d5176611e904bb0743467c12b6148';
		const a = new TronWeb({
			fullHost: 'https://api.acscan.net',
			privateKey,
		});
		const CONTRACT = 'TAH9q5mvJyu7JsB9zG7K88af2AqCAkQ6rK';
		const { abi } = await a.trx.getContract(CONTRACT);
		const contract = a.contract(abi.entrys, CONTRACT);
		const owner = b;
		const balance = await contract.methods.balanceOf(owner).call();
		this.setState({ balanceC:balance });
		const name = await contract.methods.name().call();
		this.setState({ nameC:name });
	}
	render() {
		const { data1 } = this.state;
		const {nameC} = this.state;
		const {balanceC} = this.state;
		let i = 1
		const data = [];
		for (let key in data1) {
			

			if(key == 1){
				data.push({
					key: i,
					num: i,
					asset: nameC,
					value: balanceC,
				}); i++

			}
		}
		const columns = [
			{
				title: "No.",
				dataIndex: "num"
			},
			{
				title: "Asset",
				dataIndex: "asset",
				render: record =>{ return <span>{record}</span>}
			},
			{
				title: "Value",
				dataIndex: "value",
				render: record => { return currencyFormat(record); }
			}
			
		];
		
		return (
			<AssetTable>
				<TitleAssetTable>
					<TableTitle>Asset</TableTitle>
				</TitleAssetTable>
				<Table
					columns={columns}
					dataSource={data}
					pagination={{ pageSize: 50 }}
					scroll={{ y: 240 }}
				/>

			</AssetTable>


		);
	}
}


const mapStateToProps = (state) => {

	return {
		account: state.account,
	};
};
const mapDispatchToProps = dispatch => {
	return {
		loadAccountDetails: (addr) => {
			dispatch(loadAccountDetails(addr));
		},
	};
};
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
	AssetsCard
);