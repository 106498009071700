import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CopyOutlined } from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { TableRow, Th } from './style';
import { StyledLink } from './style';
import { loadWitnesses } from '../../actions/witnesses';
import { FULL_HOST } from "../../config/config"
const TronWeb = require('tronweb')
const tronWeb = new TronWeb({
	fullHost: FULL_HOST,
});

class BlockProducer extends Component {
	render() {
		let { producer_name } = this.props;
		let { producer_hash } = this.props;
		const witnesses = this.props.witnesses.witnesses
		return (
			<TableRow>
				<Th>
					<span>Produced by</span>:
				</Th>
				<td>
					<div>
						{/* <StyledLink to={"/account/" + producer_hash} target="_blank">{producer_name ? producer_name : producer_hash}</StyledLink><CopyToClipboard text={producer_name ? producer_name : producer_hash}><CopyOutlined /></CopyToClipboard> */}
						{(() => {
							for (var i = 0; i < witnesses?.length; i++) {
								if (witnesses[i].address === producer_hash) {
									return <span> <StyledLink to={"/account/" + tronWeb.address.fromHex(producer_hash)} target="_blank">
										{witnesses[i].address === producer_hash
										? witnesses[i].url.substring(8)
										: tronWeb.address.fromHex(producer_hash)}</StyledLink>
									<CopyToClipboard text={producer_name ? producer_name : producer_hash}><CopyOutlined /></CopyToClipboard> </span>
								}
							}
						})()}
					</div>
				</td>
			</TableRow>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		producer_name: state.block.producer_name,
		producer_hash: state.block.producer_hash,
		witnesses: state.witnesses,
	};
};
export default connect(mapStateToProps, null, null, { forwardRef: true })(BlockProducer);