import React, { Component } from "react";
import { connect } from "react-redux";
import styled from 'styled-components';
import { CopyOutlined } from '@ant-design/icons';
import { Skeleton} from 'antd';
import {toDateTime} from '../../utils/utils';
import TronWeb from "tronweb";
import {API_ADDR_NEW, FULL_HOST} from "../../config/config";
import {loadRecentBlock} from '../../actions/block';
import {loadSystemState} from "../../actions/home";
const Wrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;
const CardContent = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    flex-direction: row;
    border-bottom: 1px solid #EEEEEE;
    padding-top: 10px;
    padding-bottom: 10px;
`;
const Content = styled.div`
    padding-left: 25px;
    padding-right: 25px;
    background-color: #ffffff;
    width: 100%;
`;
const CardHeader = styled.div`
    text-align: left;
    min-width: 100px;
    font-weight: 600;
`;
const RowTitle = styled.div`
  min-width: 100px;
  text-align: left;
`;
const RowValue = styled.div`
  text-align: left;
  overflow: auto;
`;
const RowValueExt = styled.div`
  height: auto; 
  max-height: 100px;
`;
const StatusContainer = styled.div`
    display: flex;
    flex-direction: row;
`;
const StatusConfirm = styled.div`
    background-color: #E1F3E0;
    padding-left: 5px;
    padding-right: 5px;
    margin-right: 15px;
`;
const StatusBlockNum = styled.div`
    background-color: #DDDDDD;
    padding-left: 5px;
    padding-right: 5px;
    margin-right: 15px;
`;

const RowTitleUpper = styled.div`
  min-width: 100px;
  text-align: left;
  text-transform: uppercase;
`;


const Status = (status, numOfBlocks, numOFLastestBlock) => (<StatusContainer>
  <StatusConfirm>
    {numOFLastestBlock-numOfBlocks  >= 20 ? <RowTitleUpper>CONFIRMED</RowTitleUpper>: numOFLastestBlock-numOfBlocks < 0 ? null:  <RowTitleUpper>UNCONFIRMED</RowTitleUpper> }

  </StatusConfirm>
  <StatusBlockNum>
      {numOFLastestBlock -numOfBlocks >= 0 ? <RowValue>confirmed by {numOFLastestBlock -numOfBlocks } blocks</RowValue> : null }
  </StatusBlockNum>
</StatusContainer>);
const HightLight= styled.span`
color: '#E50915';
`;


class Card extends Component {


  componentDidMount() {

      this.props.loadSystemState();

  }

  render() {
    const { hash, contract, blockNum, result, timestamp, numOfBlocks, status }= this.props.transaction;


    const content = [
      { title: 'Result', value:result },
      { title: 'Status', value: Status(status, numOfBlocks,this.props.systemState.block_height)},
      { title: 'Block', value: <HightLight>{blockNum}</HightLight> },
      { title: 'Time', value: toDateTime(timestamp?timestamp:0) },
      { title: 'Contract', value: contract.type},
    ];
    return (
      <Wrap>
         <Skeleton loading={hash===""}>
        <Content>
          <CardContent>
            <CardHeader>Hash: <span>{hash} <CopyOutlined /> </span></CardHeader>
          </CardContent>
          {content.map((item,index) => {
            return (
            <CardContent key ={index} >
              <RowTitle >{item.title}:</RowTitle>
              <RowValue>{item.value}</RowValue>
            </CardContent>
            )
          })}
        </Content>
        </Skeleton>
      </Wrap>

    );
  }
}


const mapStateToProps = (state) => {
  return {
      systemState: state.system.systemState,
    transaction: state.transaction,
  };
};
const mapDispatchToProps = dispatch => {
	return {
        loadSystemState: () => {
            dispatch(loadSystemState());
        },
	};
};
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Card);
