import React from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {loadTransactions, updatePageTransactions, updatePageTransactionsLimit,} from '../../actions/transactions';
import {Table, Pagination} from 'antd';
import {Link} from "react-router-dom";
import {toTimeAgo, decimalFormat, currencyFormat} from '../../utils/utils';
import {API_ADDR_NEW, FULL_HOST} from "../../config/config";
import TronWeb from 'tronweb';
import contractAddress from "../contract/contractAddress";

const Container = styled.div`
  margin: 5px;
`;
const Title = styled.div`
  text-align: left;
  border-bottom: 5px solid #C23631;
  font-size: 20px;
  text-transform: uppercase;
`;
const RedText = styled.span`
  color: #E50915;
`;
const PagiContainer = styled.div`
  margin-top: 15px;
  text-align: right;
`;


class TransactionsList extends React.Component {


    constructor(props) {
        super(props);
        this.state = {contractName: ""};
    }

    componentDidMount() {
        const {transactions} = this.props;
        this.props.loadTransactions(transactions.transactionPage.start_item, transactions.transactionPage.page_limit);
    }

    onChange = (pageNumber, pageLimit) => {
        this.props.updatePageTransactions(pageNumber);
        var {transactions} = this.props;
        if (pageLimit !== transactions.transactionPage.page_limit) {
            this.props.updatePageTransactionsLimit(pageLimit);
        }
        this.props.loadTransactions(transactions.transactionPage.start_item, transactions.transactionPage.page_limit);
    }

     getContractName  = async (contractAddress) => {
         const tronWeb = new TronWeb({fullHost: FULL_HOST,});
         const contract =  await tronWeb.trx.getContract(contractAddress);
         this.setState({contractName : contract.name})
    }

    render() {
        const tronWeb = new TronWeb({fullHost: FULL_HOST,});
        let {transactions} = this.props;
        // console.log(transactions );
        const columns = [
            {
                title: 'No.',
                key: 'num',
                width: '5%',
                render: (value, item, index) => (index + 1 + (transactions.transactionPage.current_page - 1) * transactions.transactionPage.page_limit),
            },
            {
                title: 'Hash',
                // dataIndex: 'txID',
                key: 'hash',
                width: '12%',
                render: record => {
                    if (record.contractType === "transfer") {
                        return (
                            <Link to={"/transaction/" + record.hash}>
                            <RedText>{record.hash.substring(0, 6) + "..." + record.hash.substring(59, 65)}</RedText>
                     {/*<RedText>{record.hash}</RedText>*/}
                            </Link>


                        )
                    } else if (record.hash !== null) {
                        return (
                            <Link to={"/transaction/" + record.hash}>

                                <RedText>{record.hash.substring(0, 6) + "..." + record.hash.substring(59, 65)}</RedText>
                                {/*<RedText>{record.hash}</RedText>*/}


                            </Link>
                        )
                    } else {
                        return <span>&nbsp; &nbsp; &nbsp; -</span>
                    }
                }


            },
            {
                title: 'Block',
                key: 'blockNumber',
                width: '10%',
                render: record => {
                    if (record.contractType === "transfer") {
                        return (
                            <Link to={"/block/" + record.blockNumber}>
                                <RedText>{record.blockNumber}</RedText>
                            </Link>
                        )
                    } else if (record.contractType === "TransferAssetContract") {
                        return (
                            <Link to={"/block/" + record.blockNumber}>
                                <RedText>{record.blockNumber}</RedText>
                            </Link>
                        )
                    } else if (record.contractType === "TransferContract") {
                        return (
                            <Link to={"/block/" + record.blockNumber}>
                                <RedText>{record.blockNumber}</RedText>
                            </Link>
                        )
                    }
                }


            },
            {
                title: 'Transaction type',
                key: 'type',
                width: '15%',
                render: record => {
                    return <span>{record.contractType}</span>
                }

            },
            {
                title: 'From',
                key: 'from',
                width: '12%',
                render: record => {
                    if (record.contractType === "transfer") {
                        return (
                            <Link to={"/account/" + tronWeb.address.fromHex(record.raw_data.contract[0].parameter.value.owner_address)}>
                                <RedText>
                                    {tronWeb.address.fromHex(record.raw_data.contract[0].parameter.value.owner_address).substring(0, 6) + "..." + tronWeb.address.fromHex(record.raw_data.contract[0].parameter.value.owner_address).substring(tronWeb.address.fromHex(record.raw_data.contract[0].parameter.value.owner_address).length - 7, tronWeb.address.fromHex(record.raw_data.contract[0].parameter.value.owner_address).length - 1)}
                                </RedText>
                            </Link>
                        )
                    } else if (record.contractType === "TransferAssetContract") {
                        return (
                            <Link to={"/account/" + tronWeb.address.fromHex(record.raw_data.contract[0].parameter.value.owner_address)}>
                                <RedText>
                                    {tronWeb.address.fromHex(record.raw_data.contract[0].parameter.value.owner_address).substring(0, 6) + "..." + tronWeb.address.fromHex(record.raw_data.contract[0].parameter.value.owner_address).substring(tronWeb.address.fromHex(record.raw_data.contract[0].parameter.value.owner_address).length - 7, tronWeb.address.fromHex(record.raw_data.contract[0].parameter.value.owner_address).length - 1)}
                                </RedText>
                            </Link>

                        )
                    } else if (record.contractType === "TransferContract") {
                        return (
                            <Link to={"/account/" + tronWeb.address.fromHex(record.raw_data.contract[0].parameter.value.owner_address)}>
                                <RedText>
                                    {tronWeb.address.fromHex(record.raw_data.contract[0].parameter.value.owner_address).substring(0, 6) + "..." + tronWeb.address.fromHex(record.raw_data.contract[0].parameter.value.owner_address).substring(tronWeb.address.fromHex(record.raw_data.contract[0].parameter.value.owner_address).length - 7, tronWeb.address.fromHex(record.raw_data.contract[0].parameter.value.owner_address).length - 1)}
                                </RedText>
                            </Link>
                        )

                    }
                }
            },
            {
                title: 'To',
                key: 'to',
                width: '12%',
                render: record => {


                    if (record.contractType === "transfer") {

                        return <Link
                            to={"/account/" + tronWeb.address.fromHex(record.raw_data.contract[0].parameter.value.to_address)}>

                            <RedText>
                                {tronWeb.address.fromHex(record.decodeData[0]).substring(0, 6) + "..." + tronWeb.address.fromHex(record.decodeData[0]).substring(tronWeb.address.fromHex(record.decodeData[0]).length - 7, tronWeb.address.fromHex(record.decodeData[0]).length - 1)}
                            </RedText>
                        </Link>

                    } else if (record.contractType === "TransferAssetContract") {

                        return <Link
                            to={"/account/" + tronWeb.address.fromHex(record.raw_data.contract[0].parameter.value.to_address)}>

                            <RedText>
                                {tronWeb.address.fromHex(record.raw_data.contract[0].parameter.value.to_address).substring(0, 6) + "..." + tronWeb.address.fromHex(record.raw_data.contract[0].parameter.value.to_address).substring(tronWeb.address.fromHex(record.raw_data.contract[0].parameter.value.to_address).length - 7, tronWeb.address.fromHex(record.raw_data.contract[0].parameter.value.to_address).length - 1)}
                            </RedText>
                        </Link>


                    } else if (record.contractType === "TransferContract") {
                        return <Link
                            to={"/account/" + tronWeb.address.fromHex(record.raw_data.contract[0].parameter.value.to_address)}>
                            <RedText>
                                {tronWeb.address.fromHex(record.raw_data.contract[0].parameter.value.to_address).substring(0, 6) + "..." + tronWeb.address.fromHex(record.raw_data.contract[0].parameter.value.to_address).substring(tronWeb.address.fromHex(record.raw_data.contract[0].parameter.value.to_address).length - 7, tronWeb.address.fromHex(record.raw_data.contract[0].parameter.value.to_address).length - 1)}
                            </RedText>
                        </Link>

                    } else {
                        return <span>&nbsp; &nbsp; &nbsp; -</span>
                    }

                }
            },
            {
                title: 'Amount',
                width: '12%',
                key: 'amount',
                render: record => {
                    const decimals = record.raw_data.contract[0].parameter.value.decimals
                    if (record.contractType === "transfer") {
                        return <span>{record.decodeData[1].words[0]/Math.pow(10,decimals)}</span>
                    } else if (record.contractType === "TransferContract") {
                        return <span>{currencyFormat(decimalFormat(record.raw_data.contract[0].parameter.value.amount / 1000000))} ACG</span>
                    } else if (record.contractType === "TransferAssetContract") {
                        return <span>{currencyFormat(decimalFormat(record.raw_data.contract[0].parameter.value.amount / 1000000))} ACG</span>
                    } else {
                        return <span>&nbsp; &nbsp; &nbsp; -</span>
                    }
                }

            },
            {
                title: 'Asset',
                width: '10%',
                key: 'asset',
                render:    record => {



                    if (record.contractType === "transfer") {
                        // const contract =  tronWeb.trx.getContract(record.raw_data.contract[0].parameter.value.contract_address);
                        // this.getContractName(record.raw_data.contract[0].parameter.value.contract_address);

                        // console.log(this.state.contractName);

                        // return <span>{this.state.contractName}</span>
                        return  <span>{record.raw_data.contract[0].parameter.value.contract_name}</span>
                    } else if (record.contractType === "TransferAssetContract") {
                        return <RedText>{record.raw_data.contract[0].parameter.value.asset_name}</RedText>

                    } else if (record.contractType === "TransferContract") {
                        return <span>&nbsp; &nbsp; &nbsp; -</span>

                    }
                }
            },
            {
                title: 'Age',
                width: '20%',
                key: 'timestamp',
                render: record => {
                    var time = record.raw_data.timestamp ? toTimeAgo(record.raw_data.timestamp) : "unknown";
                    return time;
                }
            },

        ];
        return (
            <Container>
                <Title>List of Transactions</Title>
                <div id="datetime"></div>
                <Table columns={columns}
                        dataSource={transactions.transactions}
                       rowKey="hash"
                       scroll={{x: 1500}} sticky
                       pagination={false}
                       loading={transactions.transactions !== null ? (transactions.transactions.length === 0 ? true : false) : false}
                       locale={{emptyText: 'Loading'}}
                />
                <PagiContainer>
                    <Pagination
                        current={transactions.transactionPage.start_page}
                        total={transactions.transactionPage.total_items}
                        onChange={this.onChange}
                        showSizeChanger
                        showQuickJumper/>
                </PagiContainer>
            </Container>


        );
    };
};


const mapStateToProps = (state) => {

    return {
        transactions: state.transactions,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        loadTransactions: (offset, limit) => {
            dispatch(loadTransactions(offset, limit));
        },
        updatePageTransactions: (page) => {
            dispatch(updatePageTransactions(page));
        },
        updatePageTransactionsLimit: (limit) => {
            dispatch(updatePageTransactionsLimit(limit));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(
    TransactionsList);
