import {
	TRANSACTION_DETAIL_INIT,
	TRANSACTION_DETAIL_UDPATE,
} from '../actions/transaction';

const initTran = {
	hash:"",
	blockNum: 0,
	contractAddr: "",
	result: "",
	timestamp: 0,
	status: "",
	numOfBlocks: 0,
	contract: {
        type: "",
        parameter: {
            type_url: "",
			value: "",
			raw:{},
        }
    },
	decode: []
}
export function transactionReducer(state = initTran, action) {
	switch (action.type) {
		case TRANSACTION_DETAIL_UDPATE:
			let decode = []
			if (action.payload.contractType === 'transfer'){
				decode = action.payload.decodeData
			}
			return {
				...state,
				hash: action.payload.hash,
				blockNum: action.payload.blockNumber,
				contractAddr: action.payload.raw_data.contract[0].parameter.value.contract_address,
				result: action.payload.ret[0].contractRet,
				timestamp: action.payload.raw_data.timestamp,
				numOfBlocks: action.payload.blockNumber,
				contract: {
					type: action.payload.contractType,
					parameter: {
						type_url: action.payload.raw_data.contract[0].parameter.type_url,
						value: action.payload.raw_data.contract[0].parameter.value.data,
						raw: action.payload.raw_data
					}
				},
				decode: decode,

			};
		case TRANSACTION_DETAIL_INIT:
			return initTran;
		default:
			return state;

	}
}
