import React from 'react';
import { Table, Pagination, Badge } from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { loadBlocks,  updatePageBlocks, updatePageBlocksLimit } from '../../actions/blocks';
import styled from 'styled-components';
import {toTimeAgo} from '../../utils/utils';
import { loadWitnesses } from '../../actions/witnesses';
import { FULL_HOST } from "../../config/config"
const TronWeb = require('tronweb')
const tronWeb = new TronWeb({
  fullHost: FULL_HOST,
});

const LeftHeader = styled.div`
	text-align: left;
	border-bottom: 5px solid #C23631;
	font-size: 20px;
	text-transform: uppercase;
`;
const StyledLink = styled(Link)`
	&:link, &:visited {
		color: #c23631;
	}
`;
const Wrapper = styled.div`
	margin: 5px;
`;
const Pagin = styled.div`
	margin-top: 15px;
	text-align: right!important;
`;
const BadgeGreen = styled(Badge)`
    .ant-badge-count {
        background-color: #E1F3E0;
        color: black
    }
`;

const BadgeRed = styled(Badge)`
    .ant-badge-count {
        background-color: #ff0000;
        color: black
    }
`;



class BlockTable extends React.Component {
    componentDidMount() {
        var {pageBlocks} = this.props;
        this.props.loadBlocks(pageBlocks.start_item,pageBlocks.page_limit);

    }

    onChange=(pageNumber, pageLimit) =>{
        const {pageBlocks} = this.props;
        if(pageNumber!==pageBlocks.current_page){
            this.props.updatePageBlocks(pageNumber);
        }
        if(pageLimit!==pageBlocks.page_limit){
            this.props.updatePageBlocksLimit(pageLimit);
        }
        console.log(pageBlocks.current_page+1, pageLimit)
        this.props.loadBlocks(pageBlocks.current_page+1, pageLimit);
    }

    render() {
        const witnesses = this.props.witnesses.witnesses
        var {blocks, pageBlocks} = this.props;
        const confirm = 19;
        const columns = [
            {
                title: 'No.',
                key: 'no',
                render: (value, item, index) => (index + 1+(pageBlocks.current_page-1)*pageBlocks.page_limit),
                fixed: 'left',
                width: 70,
            },
            {
                title: 'Height',
                dataIndex: 'num',
                key: 'num',
                render: text => <StyledLink key={text} to={"/block/"+text} >{text}</StyledLink>,
            },
            {
                title: 'Age',
                key: 'timestamp',
                render: record => {
                    var time = record.timestamp?toTimeAgo(record.timestamp):"unknown";
                    return time;
                }
            },
            {
                title: 'Status',
                key: 'status',
                render: () => confirm>=19?<BadgeGreen count="UNCOMFIRMED"/>:<BadgeRed count="UNCOMFIRMED"/>
            },
            {
                title: 'Transactions',
                dataIndex: 'transactions',
                key: 'transactions',
                render: text => <span>{text}</span>,
            },
            {
                title: 'Producer',
                dataIndex: 'witness_address',

                key: 'witness_address',
                render: text =>{
                    for (var i = 0; i < witnesses?.length; i++) {
                        if (witnesses[i].address === text) {
                         return <StyledLink to={`/account/${tronWeb.address.fromHex(text)}`}>
                            {witnesses[i].address === text
                              ? witnesses[i].url.substring(8)
                              : tronWeb.address.fromHex(text)}
                          </StyledLink>
                        }
                      }
                }
                // render: record => <StyledLink key={record.witness_name} to={`/account/${record.witness_address}`} >{record.witness_name}</StyledLink>,
            }
        ];
        return (
            <Wrapper>
                <LeftHeader>List of Blocks</LeftHeader>
                <Table columns={columns}
                       dataSource={blocks}
                       rowKey="num"
                       scroll={{ x: 1300 }} sticky
                       pagination={false}
                       loading={blocks&&blocks.length === 0 ? true:false}
                       locale={{ emptyText: 'Loading' }}
                />
                <Pagin>
                    <Pagination
                        current={pageBlocks.start_page}
                        total={pageBlocks.total_items}
                        onChange={this.onChange}
                        showSizeChanger
                        showQuickJumper/>
                </Pagin>
            </Wrapper>

        );
    }
}


const mapStateToProps = (state) => {
    return {
        blocks: state.blocks,
        pageBlocks:state.pageBlocks,
        witnesses: state.witnesses,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        updatePageBlocks: (page) => {
            dispatch(updatePageBlocks(page));
            dispatch(loadWitnesses());
        },
        updatePageBlocksLimit: (limit) => {
            dispatch(updatePageBlocksLimit(limit));
        },
        loadBlocks: (offset, limit) => {
            dispatch(loadBlocks(offset, limit));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(BlockTable);
