import React from 'react';
import { Input, message } from 'antd';
import { connect } from 'react-redux';
import { search, SEARCH_REQUESTING, SEARCH_SUCCESS } from '../actions/home';
import { Redirect } from 'react-router-dom';
import TronWeb from "tronweb";
import {FULL_HOST} from "../config/config";
const { Search } = Input;

const PALCEHOLDER = 'Block Num/ Block Hash/ Transaction Hash';
const SEARCHTITLE = 'Search';


class SearchBox extends React.Component {

	constructor(props) {
		super(props);
		this.searchBoxRef = React.createRef();
	}
	onSearch = (keyWord) => {
		if (keyWord === "") {
			message.info('Invalid input', 1);
		}
		else {
			this.props.search(keyWord, () => this.searchBoxRef.current.setState(state => ({ ...state, value: "" })));
		}

	}
	onResultBlockNotMinedYet =(block) =>{
		message.warn(`Block : ${block} not mined yet !`, 3);
	}
	render() {
		const searchState = this.props.state;
		const objType = this.props.type;
		const key = this.props.searchKey;
		if (searchState === SEARCH_SUCCESS) {
			switch (objType.type) {
				case undefined:
					if(key.length === 34){
						return <Redirect to={`/account/${key}`} />;
					} else {
						return <Redirect to={`/notfound`} />;
					}
				case "transaction":
					return <Redirect to={`/transaction/${key}`} />;
				case "block":
					if(objType.data === null){
						this.onResultBlockNotMinedYet(key);
						return null;
					}else {
						return <Redirect to={`/block/${key}`} />;
					}
				case "account":
					return <Redirect to={`/account/${key}`} />;
				case "token":
					return <Redirect to={`/token/${key}`} />;
			}
		}
		return (
			<Search
				placeholder={PALCEHOLDER}
				enterButton={SEARCHTITLE}
				onSearch={this.onSearch}
				loading={SEARCH_REQUESTING === this.props.state}
				ref={this.searchBoxRef}
			/>

		);
	}
}
const mapStateToProps = (state) => {
	return {
		state: state.search.state,
		type: state.search.type,
		searchKey: state.search.key,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		search: (para, callback) => {
			dispatch(search(para, callback));
		},
	}
};
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(SearchBox);
