import React from "react";
import { List } from "antd";
import { BlockOutlined } from "@ant-design/icons";
import PerfectScrollbar from "react-perfect-scrollbar";
import { connect } from "react-redux";
import { loadRecentTrans } from "../../actions/mqttTransaction";
import { Row, Col } from "antd";
import {
  RecentListTitleFrame,
  RecentListTitle,
  RecentListContentFrame,
  RecentItem,
  RecentItemData,
  StyledLink,
  RecentRightCol,
} from "./recent-list";
import { toTimeAgo, currencyFormat, decimalFormat } from "../../utils/utils";
import {FULL_HOST} from "../../config/config"
const TronWeb = require('tronweb')
const tronWeb = new TronWeb({
  fullHost: FULL_HOST,
});

class TransactionList extends React.Component {
  componentDidMount() {
    this.props.loadRecentTrans();
  }
  tranItem = (tran) => {
    var amount = null;
    const contractType = tran.contractType;
    const ownerAddress = tronWeb.address.fromHex(tran.raw_data.contract[0].parameter.value.owner_address);
    const toAddress = tronWeb.address.fromHex(tran.raw_data.contract[0].parameter.value.to_address);
    const decimals = tran.raw_data.contract[0].parameter.value.decimals
    switch (contractType) {
      default:
        amount = null;
        break;
      case "TransferContract":
        amount =
            currencyFormat(
                decimalFormat(tran.raw_data.contract[0].parameter.value.amount / 1000000)
            ) + " ACG";
        break;
      case "TransferAssetContract":
        amount = currencyFormat(tran.raw_data.contract[0].parameter.value.amount);
        break;
      case "transfer" :
        amount = tran.decodeData[1].words[0]/Math.pow(10,decimals)
        break;
    }
    return (
        <List.Item key={tran.hash}>
          <RecentItem>
            <Row>
              <Col xs={24} sm={24} md={12}>
              <span>
                Transaction:
                <RecentItemData>
                  {
                    tran.contractType === 'transfer' ? (
                        <StyledLink to={`/transaction/${tran.hash}?type=transfer`} target="_blank">
                          {tran.hash.substring(0, 24) + "..."}
                        </StyledLink>
                    ) : (
                        <StyledLink to={`/transaction/${tran.hash}`} target="_blank">
                          {tran.hash.substring(0, 24) + "..."}
                        </StyledLink>
                    )
                  }
                </RecentItemData>
              </span>
              </Col>
              <RecentRightCol xs={24} sm={24} md={12}>
              <span>
                <RecentItemData>
                  {amount}&nbsp;
                  {tran.contractType === 'transfer'? (
                      <StyledLink
                          to={`/token/${tronWeb.address.fromHex(tran.raw_data.contract[0].parameter.value.contract_address)}`}
                          // to={`/token/${tran.raw_data.contract[0].parameter.value.contract_address}`}
                          target="_blank"
                      >
                        {tran.raw_data.contract[0].parameter.value.contract_name}
                      </StyledLink>
                  ) : tran.contractType === "TransferAssetContract" ? (
                      <StyledLink
                          // to={`/token/${tran.raw_data.contract[0].parameter.value.contract_address}`}
                          to={`/token/${tronWeb.address.fromHex(tran.raw_data.contract[0].parameter.value.contract_address)}`}
                          target="_blank"
                      >
                        {ownerAddress}
                      </StyledLink>
                  ) : null}
                </RecentItemData>
              </span>
              </RecentRightCol>
            </Row>
            <Row>
              <Col xs={0} sm={0} md={24}>
                {tran.contractType === "TransferAssetContract" ||
                (tran.contractType === "TransferContract" &&
                    tran.raw_data.contract[0].parameter.value.owner_address &&
                    tran.raw_data.contract[0].parameter.value.to_address) ? (
                    <div>
                      <span>From </span>
                      <StyledLink
                          to={"/account/" + tronWeb.address.fromHex(tran.raw_data.contract[0].parameter.value.owner_address)}
                          target="_blank"
                      >
                        {ownerAddress.substring(0, 7) +
                        "..." +
                        ownerAddress.substring(
                            ownerAddress - 4,
                            ownerAddress - 1
                        )}
                      </StyledLink>
                      <span> To </span>
                      <StyledLink
                          to={"/account/" + tronWeb.address.fromHex(tran.raw_data.contract[0].parameter.value.to_address)}
                          target="_blank"
                      >
                        {toAddress.substring(0, 7) +
                        "..." +
                        toAddress.substring(
                            toAddress.length - 4,
                            toAddress.length - 1
                        )}
                      </StyledLink>
                    </div>
                ) : tran.contractType === 'transfer' ? (
                    <div>
                      <span>From </span>
                      <StyledLink
                          to={"/account/" + tronWeb.address.fromHex(tran.raw_data.contract[0].parameter.value.owner_address)}
                          target="_blank"
                      >
                        { ownerAddress.substring(0, 7) +
                        "..." +
                        ownerAddress.substring(
                            ownerAddress - 4,
                            ownerAddress - 1
                        )}
                      </StyledLink>
                      <span> To </span>
                      <StyledLink
                          to={"/account/" + tronWeb.address.fromHex(tran.decodeData[0])}
                          target="_blank"
                      >
                        {tronWeb.address.fromHex(tran.decodeData[0]).substring(0, 7) +
                        "..." +
                        tronWeb.address.fromHex(tran.decodeData[0]).substring(
                            tronWeb.address.fromHex(tran.decodeData[0]).length - 4,
                            tronWeb.address.fromHex(tran.decodeData[0]).length - 1
                        )}
                      </StyledLink>
                    </div>
                ) : (
                    <br></br>
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={24} md={12}>
              <span>
                Type:<RecentItemData>{tran.contractType}</RecentItemData>
              </span>
              </Col>
              <RecentRightCol xs={24} sm={24} md={12}>
              <span>
                <RecentItemData>
                  {
                    tran.raw_data !== undefined ? (
                        tran.raw_data.timestamp ? toTimeAgo(tran.raw_data.timestamp) : "unknown"
                        ) : "unknown"
                  }
                </RecentItemData>
              </span>
              </RecentRightCol>
            </Row>
          </RecentItem>
        </List.Item>
    );
  };
  render() {
    let trans = this.props.trans == null ? [] : this.props.trans;
    trans.sort(function (a, b) {
      return b.timestamp - a.timestamp;
    });
    return (
      <div>
        <RecentListTitleFrame>
          <BlockOutlined />
          <RecentListTitle>Recent Transactions</RecentListTitle>
        </RecentListTitleFrame>
        <RecentListContentFrame>
          <PerfectScrollbar>
            <List
              dataSource={trans}
              renderItem={(tran) => this.tranItem(tran)}
              loading={trans.length === 0 ? true : false}
              locale={{ emptyText: "Loading" }}
            />
          </PerfectScrollbar>
        </RecentListContentFrame>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    trans: state.homeTrans.trans,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loadRecentTrans: () => {
      dispatch(loadRecentTrans());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(TransactionList);
