import React from 'react';
import { Table, Tag } from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { loadWitnesses } from '../../actions/witnesses';
import styled from 'styled-components';
import {FULL_HOST} from "../../config/config"
const TronWeb = require('tronweb')
const tronWeb = new TronWeb({
    fullHost: FULL_HOST,
});

const StyleOutLink = styled.a`
	&:link, &:visited {
		color: #c23631;
	}
`;
const LeftHeader = styled.div`
	text-align: left;
	border-bottom: 5px solid #C23631;
	font-size: 20px;
	text-transform: uppercase;
`;
const StyledLink = styled(Link)`
	&:link, &:visited {
		color: #c23631;
	}
`;
const Wrapper = styled.div`
	margin: 5px;
`;
const columns = [
    {
        title: 'No.',
        key: 'no',
        render: (value, item, index) => (index + 1),
        fixed: 'left',
        width: 70,
    },
    {
        title: 'Address',
        dataIndex: 'address',
        key: 'address',
        render: text => <StyledLink key={text} to={`/account/${tronWeb.address.fromHex(text)}`}>{tronWeb.address.fromHex(text)}</StyledLink>,
    },
    {
        title: 'Vote Count',
        dataIndex: 'voteCount',
        key: 'voteCount',
        render: text => <span>{text}</span>
    },
    {
        title: 'URL',
        dataIndex: 'url',
        key: 'url',
        render: text => <StyleOutLink key={text} href={text}>{text}</StyleOutLink>
    },
    {
        title: 'Total Produced',
        key: 'totalProduced',
        dataIndex: 'totalProduced',
        render: text => <span>{text}</span>
    },
    {
        title: 'Total Missed',
        key: 'totalMissed',
        dataIndex: 'totalMissed',
        render: text => <span>{text ? text : 0}</span>
    },
    {
        title: 'Lastest block num',
        key: 'latestBlockNum',
        dataIndex: 'latestBlockNum',
        render: text => <StyledLink key={text} to={`/block/${text}`}>{text}</StyledLink>,
    },
    {
        title: 'Lastest Slot Num',
        key: 'latestSlotNum',
        dataIndex: 'latestSlotNum',
        render: text => <StyledLink key={text} to={`/block/${text}`}>{text}</StyledLink>,
    },
    {
        title: 'Running',
        key: 'isJobs',
        dataIndex: 'isJobs',
        render: text => <Tag color={text ? 'green' : 'red'}>{text ? "RUNNING" : "NOT RUNNING"}</Tag>,
        fixed: 'right',
        defaultSortOrder: 'descend',
        sorter: (a, b) => {
            const isRunA = a.is_jobs ? 1 : 0;
            const isRunB = b.is_jobs ? 1 : 0;
            return isRunA - isRunB;
        },
    },
];

class WitnessTable extends React.Component {
    componentDidMount() {
        this.props.loadWitnesses();
    }
    render() {
        const witnesses = this.props.witnesses;
        console.log(witnesses);
        return (
            <Wrapper>
                <LeftHeader>List of Witness</LeftHeader>
                <Table columns={columns}
                       dataSource={witnesses.witnesses}
                       rowKey="address"
                       scroll={{ x: 1500 }} sticky
                       loading={ witnesses.witnesses !== null ? (witnesses.length === 0 ? true : false) : false }
                       locale={{ emptyText: 'Loading' }}
                />
            </Wrapper>

        );
    }
}


const mapStateToProps = (state) => {
    return {
        witnesses: state.witnesses,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        loadWitnesses: () => {
            dispatch(loadWitnesses());
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(WitnessTable);
