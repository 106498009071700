import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { loadTransfer, updatePageTransfer, updatePageTransferLimit, } from '../../actions/transfer';
import { Table, Pagination } from 'antd';
import { Link } from "react-router-dom";
import { addressFormat, hashFormat, toTimeAgo, decimalFormat, currencyFormat } from '../../utils/utils';
import { get } from 'lodash'

const Container = styled.div`
	margin: 5px;
`;
const Title = styled.div`
	text-align: left;
	border-bottom: 5px solid #C23631;
	font-size: 20px;
	text-transform: uppercase;
`;
const RedText = styled.span`
	 color: #E50915;
`;
const PagiContainer = styled.div`
	margin-top: 15px;
	text-align: right;
`;


class TransferList extends React.Component {

	componentDidMount() {
		console.log(this.props);
		const { transfer } = this.props;
		this.props.loadTransfer(transfer.transferPage.start_item, transfer.transferPage.page_limit);
	}

	onChange = (pageNumber, pageLimit) => {
		this.props.updatePageTransfer(pageNumber);
		var { transfer } = this.props;
		if(pageLimit !== transfer.transferPage.page_limit){
			this.props.updatePageTransferLimit(pageLimit);
		}
		this.props.loadTransfer(transfer.transferPage.start_item, transfer.transferPage.page_limit);
	}
	render() {
		var { transfer} = this.props;
		const columns = [
			{
				title: 'No.',
				key: 'num',
				width: '5%',
				render: (value, item, index) => (index + 1 + (transfer.transferPage.current_page - 1)*transfer.transferPage.page_limit),
			},
			{
				title: 'Hash',
				dataIndex: 'hash',
				key: 'hash',
				width: '12%',
				render: text =>
                    <RedText>{ hashFormat(text) }</RedText>
			},
			{
				title: 'Block',
				dataIndex: 'blockNumber',
				key: 'blockNumber',
				width: '10%',
				render: text =>
					<Link to={"/block/" + text}>
						<RedText>{text}</RedText>
					</Link>
			},
			{
				title: 'Contract type',
				dataIndex: 'contractType',
				width: '15%',
				key: 'type',
				render: text =>{
					return <span>{text}</span>
				}
			},
			{
				title: 'From',
				dataIndex: 'raw_data',
				key: 'from',
				width: '12%',
				render: record =>{
					if (record.contract[0].parameter.value.owner_address) {
						return  <Link to={"/account/" + window.tronWeb.address.fromHex(record.contract[0].parameter.value.owner_address)}>
							<RedText>
								{addressFormat(window.tronWeb.address.fromHex(record.contract[0].parameter.value.owner_address))}
							</RedText>
							</Link>
					}else {
						return <span>-</span>
					}
				}
			},
			{
				title: 'To',
				dataIndex: 'decodeData',
				key: 'to',
				width: '12%',
				render: record =>{
					if (record[0]) {
						return <Link to={"/account/" + window.tronWeb.address.fromHex(record[0])}>
									<RedText>{ addressFormat(record[0]) }</RedText>
								</Link>
					} else {
						return <span>&nbsp; &nbsp; &nbsp; -</span>
					}
				}
			},
			{
				title: 'Amount',
				width: '12%',
				key: 'amount',
				render: record =>{
					const decimals = record.raw_data.contract[0].parameter.value.decimals
					if (record.decodeData[1].words[0]) {
						return <span>{record.decodeData[1].words[0]/Math.pow(10,decimals)}</span>
					}
					else{
						return <span>&nbsp; &nbsp; &nbsp; -</span>
					}
				}
			},
			{
				title: 'Asset',
				width: '10%',
				key: 'asset',
				render: record =>{
					return <span>{record.raw_data.contract[0].parameter.value.contract_name}</span>
				}
			},
			{
				title: 'Age',
				width: '20%',
				dataIndex: 'raw_data',
				key: 'timestamp',
				render: record => {
					return record.timestamp ? toTimeAgo(record.timestamp) : "unknown";
				}
			},

		];
		return (
			<Container>
				<Title>List of Transfer</Title>
				<div id="datetime"></div>
				<Table columns={columns}
					dataSource={transfer.transfer}
					rowKey="hash"
					scroll={{ x: 1500 }} sticky
					pagination={false}
					loading={ get(transfer, 'transfer.length', 0) === 0}
					locale={{ emptyText: 'Loading' }}
				/>
				<PagiContainer>
					<Pagination
						current={transfer.transferPage.start_page}
						total={transfer.transferPage.total_items}
						onChange={this.onChange}
						showSizeChanger
						showQuickJumper />
				</PagiContainer>
			</Container>


		);
	};
};



const mapStateToProps = (state) => {
	return {
		transfer: state.transfer,
	};
};
const mapDispatchToProps = dispatch => {
	return {
		loadTransfer: (offset, limit) => {
			dispatch(loadTransfer(offset, limit));
		},
		updatePageTransfer: (page) => {
			dispatch(updatePageTransfer(page));
		},
		updatePageTransferLimit: (limit) => {
			dispatch(updatePageTransferLimit(limit));
		},
	};
};
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
	TransferList);
