import {Component} from "react";
import {connect} from "react-redux";
import {addressFormat, hashFormat, toTimeAgo} from "../../utils/utils";
import {Link} from "react-router-dom";
import {Pagination, Table} from "antd";
import {get} from "lodash";
import styled from "styled-components";
import {loadTokenTransfers, updateTabTransfer, updateTabTransferLimit} from "../../actions/token";

const Container = styled.div`
	margin: 5px;
`;
const Title = styled.div`
	text-align: left;
	border-bottom: 5px solid #C23631;
	font-size: 20px;
	text-transform: uppercase;
`;
const RedText = styled.span`
	 color: #E50915;
`;
const PagiContainer = styled.div`
	margin-top: 15px;
	text-align: right;
`;


class TransfersTab extends Component{
    onChange = (pageNumber,pageLimit) => {
        const { tokenTransfer } = this.props;
        // console.log(tokenTransfer);
        console.log(this.props);
        if(pageNumber!== tokenTransfer.transferPage.current_page){
            this.props.updateTabTransfer(pageNumber);
        }
        if(pageLimit!==tokenTransfer.transferPage.page_limit){
            this.props.updateTabTransferLimit(pageLimit);
        }
        console.log(tokenTransfer.transferPage.current_page+1, pageLimit)
        this.props.loadTokenTransfers( this.props.token.id ,pageLimit,tokenTransfer.transferPage.current_page+1);
    }

    render() {
        let {tokenTransfer} = this.props;
        // console.log(this.props);
        let transfer= tokenTransfer.transfer;
        const uniqueTransfer = [...new Map(transfer.map(item => [item["transactionId"], item])).values()];

        // console.log(uniqueTransfer[0]);

        const columns = [
            {
                title: 'Hash',
                dataIndex: 'transactionId',
                key: 'hash',
                width: '6%',
                render: text =>
                    <RedText>{ hashFormat(text) }</RedText>
            },
            {
                title: 'Block',
                dataIndex: 'blockNumber',
                key: 'blockNumber',
                width: '6%',
                render: text =>
                    <Link to={"/block/" + text}>
                        <RedText>{text}</RedText>
                    </Link>
            },
            {
                title: 'Contract type',
                dataIndex: 'eventName',
                width: '7%',
                key: 'type',
                render: text =>{
                    return <span>{text}</span>
                }
            },
            {
                title: 'From',
                dataIndex: '0',
                key: 'from',
                width: '7%',
                render: text =>{

                        return  <Link to={"/account/" + text}>
                            <RedText>
                                {addressFormat(text)}
                            </RedText>
                        </Link>

                }
            },
            {
                title: 'To',
                dataIndex: '1',
                key: 'to',
                width: '7%',
                render: text =>{
                    return  <Link to={"/account/" + text}>
                        <RedText>
                            {addressFormat(text)}
                        </RedText>
                    </Link>
                }
            },
            {
                title: 'Amount',
                dataIndex: '2',

                width: '7%',
                key: 'amount',
                render: text =>{
                    const decimals = this.props.token.decimals
                    if (text) {
                        return <span>{text/Math.pow(10,decimals)}</span>
                    }
                    else{
                        return <span>&nbsp; &nbsp; &nbsp; -</span>
                    }
                }
            },
            {
                title: 'Age',
                width: '10%',
                dataIndex: 'timeStamp',
                key: 'timestamp',
                render: text => {
                    return text? toTimeAgo(text) : "unknown";
                }
            },

        ];
        return (
            <Container>
                <div id="datetime"></div>
                <Table columns={columns}
                       dataSource={uniqueTransfer}
                       rowKey="transactionId"
                       scroll={{ x: 1500 }} sticky
                       pagination={true}
                       loading={ uniqueTransfer.length === 0}
                       locale={{ emptyText: 'Loading' }}
                />
                <PagiContainer>
                    <Pagination
                        current={tokenTransfer.transferPage.current_page}
                        // total= {tokenTransfer.transferPage.current_page}
                        onChange={this.onChange}
                        showSizeChanger
                        showQuickJumper
                    />
                </PagiContainer>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        token: state.token,
        tokenTransfer: state.tokenTransfer,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        updateTabTransfer: (page) => {
            dispatch(updateTabTransfer(page));
        },
        updateTabTransferLimit: (limit) => {
            dispatch(updateTabTransferLimit(limit));
        },
        loadTokenTransfers: (id,limit,start) => {
            dispatch(loadTokenTransfers(id,limit,start));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(TransfersTab);
