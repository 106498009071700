import React, { Component } from 'react';
import { connect } from 'react-redux';
import Card from './card';
import Trigger from './lowerCard/triggerSmartContract';
import TransferContract from './lowerCard/transferContract';
import TransferTran from "./lowerCard/transferTran";
import TransferAssetContract from './lowerCard/transferAssetContract';
import CreateSmartContract from './lowerCard/createSmartContract';
import IssueAssetContract from './lowerCard/issueAssetContract';
import styled from 'styled-components';
import { loadTransactionDetails  } from '../../actions/transaction';
import ReactJson from 'react-json-view';
import {Skeleton, Transfer} from 'antd';

const Container = styled.div`
	margin: 5px;
`;
const CardTitle = styled.div`
    border-bottom: 5px solid #C23631;
    font-size: 20px;
	text-align: left;
	`;



class TransactionDetails extends Component {
    contractDetails=(type, raw)=>{
        switch(type){
            case "TriggerSmartContract": return <Trigger/>;
            case "TransferContract": return <TransferContract/>;
            case "TransferAssetContract": return <TransferAssetContract/>;
            case "CreateSmartContract": return <CreateSmartContract/>;
            case "AssetIssueContract": return <IssueAssetContract/>;
            case "transfer" : return <TransferTran/>;
            case "": return <Skeleton loading></Skeleton>
            default: return      <ReactJson src={raw} />;
        };
    }

    componentDidMount(){
        if (window.location.href.indexOf('type=transfer') !== -1){
            this.props.loadTransactionDetails(this.props.match.params.id, 'transfer');
        } else {
            this.props.loadTransactionDetails(this.props.match.params.id, 'tran');
        }

    }
    render() {
        const TransactionType = this.props.transaction.contract.type;
        const jsonContract = this.props.transaction.contract.parameter.raw;
        return (
            <Container>
                <CardTitle>
                    <h3 >TRANSACTION DETAILS</h3>
                </CardTitle>
                <Card/>
                {this.contractDetails(TransactionType,jsonContract)}
            </Container>


        );
    };
};



const mapStateToProps = (state) => {

    return {
        transaction: state.transaction,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        loadTransactionDetails: (txHash, type) => {
            dispatch(loadTransactionDetails(txHash, type));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    TransactionDetails);
