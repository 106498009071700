import React, {Component} from "react";
import {connect} from "react-redux";
import {Button, Collapse, Form, Input, message, Modal, Space} from "antd";
import styled from "styled-components";
import TronWeb from "tronweb";
import {FULL_HOST} from "../../config/config";

const {Panel} = Collapse;

const WrapperCustom = styled.div`
  padding-left: 20px;
  text-align: left;
  font-size: 20px;
  //text-transform: uppercase;
`;


function onFinishFailed(errorInfo) {
    console.log('Failed:', errorInfo);
}

const abi = [
    {
        "outputs": [
            {
                "type": "string"
            }
        ],
        "constant": true,
        "name": "name",
        "stateMutability": "View",
        "type": "Function"
    },
    {
        "outputs": [
            {
                "name": "success",
                "type": "bool"
            }
        ],
        "inputs": [
            {
                "name": "_spender",
                "type": "address"
            },
            {
                "name": "_value",
                "type": "uint256"
            }
        ],
        "name": "approve",
        "stateMutability": "Nonpayable",
        "type": "Function"
    },
    {
        "outputs": [
            {
                "type": "uint256"
            }
        ],
        "constant": true,
        "name": "totalSupply",
        "stateMutability": "View",
        "type": "Function"
    },
    {
        "outputs": [
            {
                "name": "success",
                "type": "bool"
            }
        ],
        "inputs": [
            {
                "name": "_from",
                "type": "address"
            },
            {
                "name": "_to",
                "type": "address"
            },
            {
                "name": "_value",
                "type": "uint256"
            }
        ],
        "name": "transferFrom",
        "stateMutability": "Nonpayable",
        "type": "Function"
    },
    {
        "outputs": [
            {
                "type": "uint8"
            }
        ],
        "constant": true,
        "name": "decimals",
        "stateMutability": "View",
        "type": "Function"
    },
    {
        "outputs": [
            {
                "name": "success",
                "type": "bool"
            }
        ],
        "inputs": [
            {
                "name": "_value",
                "type": "uint256"
            }
        ],
        "name": "burn",
        "stateMutability": "Nonpayable",
        "type": "Function"
    },
    {
        "outputs": [
            {
                "type": "uint256"
            }
        ],
        "constant": true,
        "inputs": [
            {
                "type": "address"
            }
        ],
        "name": "balanceOf",
        "stateMutability": "View",
        "type": "Function"
    },
    {
        "outputs": [
            {
                "name": "success",
                "type": "bool"
            }
        ],
        "inputs": [
            {
                "name": "_from",
                "type": "address"
            },
            {
                "name": "_value",
                "type": "uint256"
            }
        ],
        "name": "burnFrom",
        "stateMutability": "Nonpayable",
        "type": "Function"
    },
    {
        "outputs": [
            {
                "type": "address"
            }
        ],
        "constant": true,
        "name": "owner",
        "stateMutability": "View",
        "type": "Function"
    },
    {
        "outputs": [
            {
                "type": "string"
            }
        ],
        "constant": true,
        "name": "symbol",
        "stateMutability": "View",
        "type": "Function"
    },
    {
        "outputs": [
            {
                "name": "success",
                "type": "bool"
            }
        ],
        "inputs": [
            {
                "name": "_to",
                "type": "address"
            },
            {
                "name": "_value",
                "type": "uint256"
            }
        ],
        "name": "transfer",
        "stateMutability": "Nonpayable",
        "type": "Function"
    },
    {
        "outputs": [
            {
                "type": "bool"
            }
        ],
        "constant": true,
        "inputs": [
            {
                "type": "address"
            }
        ],
        "name": "frozenAccount",
        "stateMutability": "View",
        "type": "Function"
    },
    {
        "outputs": [
            {
                "name": "success",
                "type": "bool"
            }
        ],
        "inputs": [
            {
                "name": "_spender",
                "type": "address"
            },
            {
                "name": "_value",
                "type": "uint256"
            },
            {
                "name": "_extraData",
                "type": "bytes"
            }
        ],
        "name": "approveAndCall",
        "stateMutability": "Nonpayable",
        "type": "Function"
    },
    {
        "outputs": [
            {
                "type": "uint256"
            }
        ],
        "constant": true,
        "inputs": [
            {
                "type": "address"
            },
            {
                "type": "address"
            }
        ],
        "name": "allowance",
        "stateMutability": "View",
        "type": "Function"
    },
    {
        "inputs": [
            {
                "name": "newOwner",
                "type": "address"
            }
        ],
        "name": "transferOwnership",
        "stateMutability": "Nonpayable",
        "type": "Function"
    },
    {
        "inputs": [
            {
                "name": "initialSupply",
                "type": "uint256"
            },
            {
                "name": "tokenName",
                "type": "string"
            },
            {
                "name": "tokenSymbol",
                "type": "string"
            }
        ],
        "stateMutability": "Nonpayable",
        "type": "Constructor"
    },
    {
        "inputs": [
            {
                "name": "target",
                "type": "address"
            },
            {
                "name": "frozen",
                "type": "bool"
            }
        ],
        "name": "FrozenFunds",
        "type": "Event"
    },
    {
        "inputs": [
            {
                "indexed": true,
                "name": "from",
                "type": "address"
            },
            {
                "indexed": true,
                "name": "to",
                "type": "address"
            },
            {
                "name": "value",
                "type": "uint256"
            }
        ],
        "name": "Transfer",
        "type": "Event"
    },
    {
        "inputs": [
            {
                "indexed": true,
                "name": "_owner",
                "type": "address"
            },
            {
                "indexed": true,
                "name": "_spender",
                "type": "address"
            },
            {
                "name": "_value",
                "type": "uint256"
            }
        ],
        "name": "Approval",
        "type": "Event"
    },
    {
        "inputs": [
            {
                "indexed": true,
                "name": "from",
                "type": "address"
            },
            {
                "name": "value",
                "type": "uint256"
            }
        ],
        "name": "Burn",
        "type": "Event"
    },
    {
        "inputs": [
            {
                "name": "target",
                "type": "address"
            },
            {
                "name": "mintedAmount",
                "type": "uint256"
            }
        ],
        "name": "mintToken",
        "stateMutability": "Nonpayable",
        "type": "Function"
    },
    {
        "inputs": [
            {
                "name": "target",
                "type": "address"
            },
            {
                "name": "freeze",
                "type": "bool"
            }
        ],
        "name": "freezeAccount",
        "stateMutability": "Nonpayable",
        "type": "Function"
    }
]

async function onFinish(values) {


    // // console.log(props.id);
    tronWeb.setAddress(values.token);
    const {abi} = await tronWeb.trx.getContract(values.token);
    // console.log(JSON.stringify(abi));
    const contract = tronWeb.contract(abi.entrys, values.token);
    let funcString = "contract.methods."+values.method.name+"(" ;
    for (let i = 0; i < values.method.inputs.length; i++) {
        funcString = funcString+  "values."+values.method.inputs[i].type+values.method.name+i ;
        if(i !== values.method.inputs.length -1){
            funcString = funcString+ ","
        }

    }
    funcString =funcString+   ").send()";
    console.log(funcString);
    console.log(values);


    let messageString = values.method.name + " ";
    for (let i = 0; i < values.method.inputs.length; i++) {
        messageString = messageString +  eval("values."+values.method.inputs[i].type+values.method.name+i) ;
        if(i !== values.method.inputs.length -1){
            messageString = messageString+ " and "
        }
    }



    try {
        const result = await eval(funcString);
        // console.log(result);
        if (result === null || result === false) {
            message.error(messageString + " fail !");
        } else {
            message.success(messageString+" : "  + result.toString());
        }

    }catch (e) {
        message.error(messageString + " fail : " +e);
    }



    // const result = await contract.methods.approve(values.addressapprove0,values.uint256approve1).send();
    // console.log(result);


}


const tronWeb = new TronWeb({fullHost: FULL_HOST,});





class WriteContractTab extends Component {

    render() {
        const id = this.props.token.owner_address;
        const {abi} = this.props;
        // console.log(abi.tokenAbi.entrys);
        var no = 0;
        return (
            <WrapperCustom>

                <Space direction="vertical" style={{width: "100%"}} size="large">
                    {

                        abi.tokenAbi.entrys.map(function (item, i) {
                            if (item.stateMutability === "Nonpayable") {
                                no = no + 1;

                                    return (
                                        <Form
                                            onFinish={onFinish}
                                            onFinishFailed={onFinishFailed}
                                            initialValues={{
                                                token: id,
                                                method: item
                                            }}
                                        >
                                            <Form.Item>
                                                <Collapse
                                                    defaultActiveKey={no}
                                                    // onChange={callback}
                                                    expandIconPosition="right"
                                                >
                                                    <Panel header={no + "." + item.name} key={no}>
                                                        <Form.Item
                                                            name="token"
                                                            hidden={true}
                                                        >
                                                            <Input/>
                                                        </Form.Item>
                                                        <Form.Item
                                                            name="method"
                                                            hidden={true}
                                                        >
                                                            <Input/>
                                                        </Form.Item>

                                                           {
                                                               item.inputs.map(function (itemInput, i) {
                                                                   return (

                                                                       <Form.Item

                                                                           label={itemInput.name}
                                                                           labelCol={{ span: 24 }}
                                                                           name={itemInput.type + item.name + i}
                                                                           rules={[
                                                                               {
                                                                                   required: true,
                                                                                   len:  itemInput.type === "address" ? 34 : null,
                                                                                   // type: itemInput.type === "uint256" ? "string"
                                                                                   //     : itemInput.type === "string" ? "string"
                                                                                   //      : itemInput.type === "bool" ? "boolean " :"string",
                                                                                   message: 'Invalid!',
                                                                               },
                                                                           ]}
                                                                       >
                                                                           <Input placeholder={itemInput.type}/>
                                                                       </Form.Item>

                                                                   );
                                                               })
                                                           }

                                                        <Button type="primary" htmlType="submit">
                                                            Write
                                                        </Button>

                                                    </Panel>
                                                </Collapse>
                                            </Form.Item>
                                        </Form>

                                    );
                                }
                        })
                    }


                </Space>
            </WrapperCustom>
        );
    }

}


const mapStateToProps = (state) => {
    return {
        token: state.token,
        abi: state.tokenAbi,
    };
};
const mapDispatchToProps = dispatch => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(WriteContractTab);
