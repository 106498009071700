import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Tabs, Select, notification, Space, Descriptions} from 'antd';
import ContractCode from './contractCode';
import ReadContract from './readContract';
import WriteContract from './writeContract';
import {Wrapper, Div} from './style';
import {updatePriKey} from '../../actions/contract';
import styled from 'styled-components';
import {CopyOutlined, SettingOutlined, CheckCircleTwoTone} from "@ant-design/icons";

const {Option} = Select;

const StyleSelect = styled(Select)`
  width: 200px;
`;

const abi = {
    "entrys": [{
        "outputs": [{"type": "string"}],
        "constant": true,
        "name": "name",
        "stateMutability": "View",
        "type": "Function"
    }, {
        "outputs": [{"name": "success", "type": "bool"}],
        "inputs": [{"name": "_spender", "type": "address"}, {"name": "_value", "type": "uint256"}],
        "name": "approve",
        "stateMutability": "Nonpayable",
        "type": "Function"
    }, {
        "outputs": [{"type": "uint256"}],
        "constant": true,
        "name": "totalSupply",
        "stateMutability": "View",
        "type": "Function"
    }, {
        "outputs": [{"name": "success", "type": "bool"}],
        "inputs": [{"name": "_from", "type": "address"}, {"name": "_to", "type": "address"}, {
            "name": "_value",
            "type": "uint256"
        }],
        "name": "transferFrom",
        "stateMutability": "Nonpayable",
        "type": "Function"
    }, {
        "outputs": [{"type": "uint8"}],
        "constant": true,
        "name": "decimals",
        "stateMutability": "View",
        "type": "Function"
    }, {
        "outputs": [{"name": "success", "type": "bool"}],
        "inputs": [{"name": "_value", "type": "uint256"}],
        "name": "burn",
        "stateMutability": "Nonpayable",
        "type": "Function"
    }, {
        "outputs": [{"type": "uint256"}],
        "constant": true,
        "inputs": [{"type": "address"}],
        "name": "balanceOf",
        "stateMutability": "View",
        "type": "Function"
    }, {
        "outputs": [{"name": "success", "type": "bool"}],
        "inputs": [{"name": "_from", "type": "address"}, {"name": "_value", "type": "uint256"}],
        "name": "burnFrom",
        "stateMutability": "Nonpayable",
        "type": "Function"
    }, {
        "outputs": [{"type": "address"}],
        "constant": true,
        "name": "owner",
        "stateMutability": "View",
        "type": "Function"
    }, {
        "outputs": [{"type": "string"}],
        "constant": true,
        "name": "symbol",
        "stateMutability": "View",
        "type": "Function"
    }, {
        "outputs": [{"name": "success", "type": "bool"}],
        "inputs": [{"name": "_to", "type": "address"}, {"name": "_value", "type": "uint256"}],
        "name": "transfer",
        "stateMutability": "Nonpayable",
        "type": "Function"
    }, {
        "outputs": [{"type": "bool"}],
        "constant": true,
        "inputs": [{"type": "address"}],
        "name": "frozenAccount",
        "stateMutability": "View",
        "type": "Function"
    }, {
        "outputs": [{"name": "success", "type": "bool"}],
        "inputs": [{"name": "_spender", "type": "address"}, {
            "name": "_value",
            "type": "uint256"
        }, {"name": "_extraData", "type": "bytes"}],
        "name": "approveAndCall",
        "stateMutability": "Nonpayable",
        "type": "Function"
    }, {
        "outputs": [{"type": "uint256"}],
        "constant": true,
        "inputs": [{"type": "address"}, {"type": "address"}],
        "name": "allowance",
        "stateMutability": "View",
        "type": "Function"
    }, {
        "inputs": [{"name": "newOwner", "type": "address"}],
        "name": "transferOwnership",
        "stateMutability": "Nonpayable",
        "type": "Function"
    }, {
        "inputs": [{"name": "initialSupply", "type": "uint256"}, {
            "name": "tokenName",
            "type": "string"
        }, {"name": "tokenSymbol", "type": "string"}], "stateMutability": "Nonpayable", "type": "Constructor"
    }, {
        "inputs": [{"name": "target", "type": "address"}, {"name": "frozen", "type": "bool"}],
        "name": "FrozenFunds",
        "type": "Event"
    }, {
        "inputs": [{"indexed": true, "name": "from", "type": "address"}, {
            "indexed": true,
            "name": "to",
            "type": "address"
        }, {"name": "value", "type": "uint256"}], "name": "Transfer", "type": "Event"
    }, {
        "inputs": [{"indexed": true, "name": "_owner", "type": "address"}, {
            "indexed": true,
            "name": "_spender",
            "type": "address"
        }, {"name": "_value", "type": "uint256"}], "name": "Approval", "type": "Event"
    }, {
        "inputs": [{"indexed": true, "name": "from", "type": "address"}, {"name": "value", "type": "uint256"}],
        "name": "Burn",
        "type": "Event"
    }, {
        "inputs": [{"name": "target", "type": "address"}, {"name": "mintedAmount", "type": "uint256"}],
        "name": "mintToken",
        "stateMutability": "Nonpayable",
        "type": "Function"
    }, {
        "inputs": [{"name": "target", "type": "address"}, {"name": "freeze", "type": "bool"}],
        "name": "freezeAccount",
        "stateMutability": "Nonpayable",
        "type": "Function"
    }]
}

class ContractCodeMenu extends Component {
    componentDidMount() {
        var {prikeys, login} = this.props;
        if (!prikeys.prikeys || prikeys.prikeys.length === 0) {
            notification.warning({
                message: 'Warning!',
                description: "You have no private key, please add somes in private key management to perform transaction!",
            });
        }
    }

    onChange = (value) => {
        this.props.updatePriKey(value);
    }

    render() {
        let {contract, prikeys} = this.props;
        var noRead = 0;
        var noWrite = 0;
        var read = contract.abi ? contract.abi.map((func, index) => {
            if (!func.stateMutability || func.stateMutability === "View" ) {
                noRead++;
                return <ReadContract key={index} func={func} no={noRead} addr={contract.contract_address}/>;
            }
            return null;
        }) : null;
        console.log(read);
        var write = contract.abi ? contract.abi.map((func, index) => {
            if (func.stateMutability === "Nonpayable" ) {
                noWrite++;
                return <WriteContract key={index} func={func} no={noWrite} addr={contract.contract_address}/>;
            }
            return null;
        }) : null;
        console.log(write);
        return (
            <Wrapper>

                <Div>

                    <Tabs defaultActiveKey="1" type="card">

                        <Tabs.TabPane tab="Code" key="1">
                            <Space>
                                <CheckCircleTwoTone twoToneColor="#52c41a"/>
                                <Descriptions title="Contract source code is verified (Perfect match)"
                                              style={{marginTop: 20}}/>
                            </Space>
                            <Descriptions>
                                <Descriptions.Item label="Contract Name">Hamburger</Descriptions.Item>
                                <Descriptions.Item label="Compiler version">solidity 0.5.8</Descriptions.Item>
                                <br/>
                                <Descriptions.Item label="Optimization">No</Descriptions.Item>
                                <Descriptions.Item label="License">None</Descriptions.Item>
                            </Descriptions>
                            <ContractCode/>
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Read Contract" key="2">
                            <Space>
                                <CheckCircleTwoTone twoToneColor="#52c41a"/>
                                <Descriptions title="Contract source code is verified (Perfect match)"
                                              style={{marginTop: 20}}/>
                            </Space>
                            <Descriptions>
                                <Descriptions.Item label="Contract Name">Hamburger</Descriptions.Item>
                                <Descriptions.Item label="Compiler version">solidity 0.5.8</Descriptions.Item>
                                <br/>
                                <Descriptions.Item label="Optimization">No</Descriptions.Item>
                                <Descriptions.Item label="License">None</Descriptions.Item>
                            </Descriptions>
                            <Wrapper>
                                <StyleSelect
                                    showSearch
                                    placeholder="Select a private key"
                                    allowClear
                                    onChange={this.onChange}
                                >
                                    {prikeys.prikeys && prikeys.prikeys.length !== 0 ? prikeys.prikeys.map((value, index) =>
                                        <Option value={value.prikey} key={index}>{value.name}</Option>) : null}
                                </StyleSelect>
                            </Wrapper>

                            {read}
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Write Contract" key="3">
                            <Space>
                                <CheckCircleTwoTone twoToneColor="#52c41a"/>
                                <Descriptions title="Contract source code is verified (Perfect match)"
                                              style={{marginTop: 20}}/>
                            </Space>
                            <Descriptions>
                                <Descriptions.Item label="Contract Name">Hamburger</Descriptions.Item>
                                <Descriptions.Item label="Compiler version">solidity 0.5.8</Descriptions.Item>
                                <br/>
                                <Descriptions.Item label="Optimization">No</Descriptions.Item>
                                <Descriptions.Item label="License">None</Descriptions.Item>
                            </Descriptions>
                            <Wrapper>
                                <StyleSelect
                                    showSearch
                                    placeholder="Select a private key"
                                    allowClear
                                    onChange={this.onChange}
                                >
                                    {prikeys.prikeys && prikeys.prikeys.length !== 0 ? prikeys.prikeys.map((value, index) =>
                                        <Option value={value.prikey} key={index}>{value.name}</Option>) : null}
                                </StyleSelect>
                            </Wrapper>
                            {write}
                        </Tabs.TabPane>
                    </Tabs>

                </Div>
            </Wrapper>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        contract: state.contract,
        prikeys: state.prikeyManagement,
        login: state.login,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updatePriKey: (key) => {
            dispatch(updatePriKey(key));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(ContractCodeMenu);
