import fetch from 'cross-fetch';
import {API_ADDR, API_ADDR_NEW, FULL_HOST} from '../config/config';
import TronWeb from "tronweb";
// import {initTokens, updatePageTokensTotal, updateTokens} from "./tokens";

export const TOKEN_DETAIL_INIT = 'TOKEN_DETAIL_INIT';
export const TOKEN_DETAIL_UPDATE = 'TOKEN_DETAIL_UPDATE';

export function initTokenDetail() {
    return {
        type: TOKEN_DETAIL_INIT,
    }
}

export function updateTokenDetail(token) {
    return {
        type: TOKEN_DETAIL_UPDATE,
        payload: token
    }
}


export function loadTokenDetail(id) {

    return async (dispatch) => {
        dispatch(initTokenDetail());

        const tronWeb = new TronWeb({fullHost: FULL_HOST,})
        tronWeb.setAddress(id);
        const {abi} = await tronWeb.trx.getContract(id);
        // console.log(JSON.stringify(abi));
        const contract = tronWeb.contract(abi.entrys, id);
        // console.log(contract);
        const res = {
            owner_address: id,
            name: await contract.methods.name().call(),
            abbr: await contract.methods.name().call(),
            total_supply: await contract.methods.totalSupply().call(),
            trx_num: 1,
            num: 1,
            description: 'Official Token ',
            url: 'www.abc.com',

            decimals: await contract.methods.decimals().call(),
        }
        dispatch(updateTokenDetail(res));
    }
}

export const TOKEN_TRANSFER_INIT = 'TOKEN_TRANSFER_INIT';
export const TOKEN_TRANSFER_UPDATE = 'TOKEN_TRANSFER_UPDATE';

export function initTokenTransfers() {
    return {
        type: TOKEN_TRANSFER_INIT,
    }
}

export function updateTokenTransfers(tokenTransfers) {
    return {
        type: TOKEN_TRANSFER_UPDATE,
        payload: tokenTransfers
    }
}


export function loadTokenTransfers(id,limit,start) {
// export function loadTokenTransfers(id,limit,sort,since,block,start) {
    return (dispatch) => {
        dispatch(initTokenTransfers());
        // fetch(`${FULL_HOST}/events/${id}?limit=${limit}&sort=${sort}&since=${since}&block=${block}&start=${start}`
        fetch(`${FULL_HOST}/events/${id}?limit=${limit}&sort=-timeStamp&start=${start}`

            , {
                method: 'GET',
                mode: 'cors',
            }).then(res => res.json()).then((res) => {
            dispatch(updateTabTransferTotal(res.length));
            dispatch(updateTokenTransfers(res));
            // console.log(res);

        }).catch(err => {
            console.log(err);
        })

    }
}

export const TAB_TRANSFER_INIT = 'TAB_TRANSFER_INIT';
export const TAB_TRANSFER_UPDATE = 'TAB_TRANSFER_UPDATE';
export const TAB_TRANSFER_TOTAL_UPDATE = 'TAB_TRANSFER_TOTAL_UPDATE';
export const TAB_TRANSFER_LIMIT_UPDATE = 'TAB_TRANSFER_LIMIT_UPDATE';

export function initTabTransfer() {
    return {
        type: TAB_TRANSFER_INIT,
    }
}

export function updateTabTransfer(page) {
    return {
        type: TAB_TRANSFER_UPDATE,
        payload: page
    }
}

export function updateTabTransferTotal(total) {
    return {
        type: TAB_TRANSFER_TOTAL_UPDATE,
        payload: total
    }
}

export function updateTabTransferLimit(limit) {
    return {
        type: TAB_TRANSFER_LIMIT_UPDATE,
        payload: limit
    }
}

export const TOKEN_ABI_INIT = 'TOKEN_ABI_INIT';
export const TOKEN_ABI_UPDATE = 'TOKEN_ABI_UPDATE';

export function initTokenAbi() {
    return {
        type: TOKEN_ABI_INIT,
    }
}

export function updateTokenAbi(abi) {
    return {
        type: TOKEN_ABI_UPDATE,
        payload: abi
    }
}

export function loadTokenAbi(id) {

    return async (dispatch) => {
        dispatch(initTokenAbi());

        const tronWeb = new TronWeb({fullHost: FULL_HOST,})
        tronWeb.setAddress(id);
        const {abi} = await tronWeb.trx.getContract(id);
        // console.log(JSON.stringify(abi));


        dispatch(updateTokenAbi(abi));
    }
}

