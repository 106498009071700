       import fetch from 'cross-fetch';
import { API_ADDR_NEW } from '../config/config';

export const TRANSFER_INIT = 'TRANSFER_INIT';
export const TRANSFER_UPDATE = 'TRANSFER_UDPATE';


export function initTransfer() {
	return {
		type: TRANSFER_INIT,
	}
}
export function updateTransfer(transfer) {
	return {
		type: TRANSFER_UPDATE,
		payload: transfer
	}
}

export function loadTransfer(offset, limit) {
	return (dispatch) => {
		dispatch(initTransfer());
		fetch(`${API_ADDR_NEW}/transfer/transfer?skip=${offset}&limit=${limit}&sort=desc`, {
			method: 'GET',
			mode: 'cors',
		}).then(res => res.json()).then((res) => {
			dispatch(updatePageTransferTotal(res.length));
			dispatch(updateTransfer(res));
		}).catch(err => {
			console.log(err);
		})
	}
}

export const PAGE_TRANSFER_INIT = 'PAGE_TRANSFER_INIT';
export const PAGE_TRANSFER_UPDATE = 'PAGE_TRANSFER_UPDATE';
export const PAGE_TRANSFER_TOTAL_UPDATE = 'PAGE_TRANSFER_TOTAL_UPDATE';
export const PAGE_TRANSFER_LIMIT_UPDATE = 'PAGE_TRANSFER_LIMIT_UPDATE';

export function initPageTransfer() {
	return {
		type: PAGE_TRANSFER_INIT,
	}
}
export function updatePageTransfer(page) {
	return {
		type: PAGE_TRANSFER_UPDATE,
		payload: page
	}
}

export function updatePageTransferTotal(total) {
	return {
		type: PAGE_TRANSFER_TOTAL_UPDATE,
		payload: total
	}
}

export function updatePageTransferLimit(limit) {
	return {
		type: PAGE_TRANSFER_LIMIT_UPDATE,
		payload: limit
	}
}
