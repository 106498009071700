import React from 'react';
import { Table} from 'antd';
import { connect } from 'react-redux';
import { loadNodes } from '../../actions/nodes';
import styled from 'styled-components';
import { get } from 'lodash';
import {FULL_HOST} from "../../config/config";
const TronWeb = require('tronweb')
const tronWeb = new TronWeb({
    fullHost: FULL_HOST,
});

const LeftHeader = styled.div`
	text-align: left;
	border-bottom: 5px solid #C23631;
	font-size: 20px;
	text-transform: uppercase;
`;

const Wrapper = styled.div`
	margin: 5px;
`;

class NodeTable extends React.Component {
	componentDidMount() {
		this.props.loadNodes();
	}
	render() {

		const columns = [
			{
				title: 'No.',
				key: 'no',
				render: (value, item, index) => (index + 1),
				fixed: 'left',
				width: 70,
			},
			{
				title: 'Host',
				key: 'host',
				dataIndex: 'address',
				render: record => {
					return tronWeb.toUtf8(record.host);
				}
			},
			{
				title: 'Port',
				key: 'port',
				dataIndex: 'address',
				render: record => {
					return record.port
				}
			},
		];

		const { nodes } = this.props;
		return (
			<Wrapper>
				<LeftHeader>List of Nodes</LeftHeader>
				<Table
					columns={ columns }
					dataSource={ nodes.nodes }
					rowKey={ record => record.address.host}
					loading={ get(nodes, 'nodes.length', 0) === 0 }
					locale={{ emptyText: 'Loading' }}
				/>
			</Wrapper>

		);
	}
}


const mapStateToProps = (state) => {
	return {
		nodes: state.nodes,
	};
};
const mapDispatchToProps = dispatch => {
	return {
		loadNodes: () => {
			dispatch(loadNodes());
		},
	};
};
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(NodeTable);
