import fetch from 'cross-fetch';
import {API_ADDR_NEW} from '../config/config';

export const TRANSACTION_DETAIL_INIT = 'TRANSACTION_DETAIL_INIT';
export const TRANSACTION_DETAIL_UDPATE = 'TRANSACTION_DETAIL_UDPATE';


export function initTransactionDetail() {
    return {
        type: TRANSACTION_DETAIL_INIT,
    }
}

export function updateTransactionDetails(tran) {
    return {
        type: TRANSACTION_DETAIL_UDPATE,
        payload: tran
    }
}

export function loadTransactionDetails(txHash, type) {
    console.log(type, 'aaaa');
    if (type === 'transfer') {
        return (dispatch) => {
            dispatch(initTransactionDetail());
            fetch(`${API_ADDR_NEW}/transfer/hash?hash=${txHash}`)
                .then(res => res.json())
                .then((res) => {
                    dispatch(updateTransactionDetails(res));
                }).catch(err => {
                console.log(err);
            })
        }
    }
    return (dispatch) => {
        dispatch(initTransactionDetail());
        fetch(`${API_ADDR_NEW}/transactions/transactionHash?transaction=${txHash}`)
            .then(res => res.json())
            .then((res) => {
                dispatch(updateTransactionDetails(res));
            }).catch(err => {
            console.log(err);
        })
    }
}
