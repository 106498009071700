import fetch from 'cross-fetch';

import {API_ADDR, API_ADDR_NEW, FULL_HOST} from '../config/config';
export const WITNESSES_INIT = 'WITNESSES_INIT';
export const WITNESSES_UPDATE = 'WITNESSES_UPDATE';


export function initWitnesses() {
	return {
		type: WITNESSES_INIT,
	}
}
export function updateWitnesses(witnesses) {
	return {
		type: WITNESSES_UPDATE,
		payload: witnesses
	}
}

export function loadWitnesses() {
	return (dispatch) => {
		dispatch(initWitnesses());
		fetch(`${FULL_HOST}/wallet/listwitnesses`, {
			method: 'GET',
			mode: 'cors',
		}).then(res => res.json()).then((res) => {
			dispatch(updateWitnesses(res));
		}).catch(err => {
			console.log(err);
		})
	}
}
