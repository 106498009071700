import fetch from 'cross-fetch';
import {WS_ADDR_NEW, API_ADDR_NEW, WSS_ADDR_NEW} from '../config/config';
const mqtt = require('mqtt');

const client = mqtt.connect(`${WSS_ADDR_NEW}`, {
    username: 'frontend',
    password: 'DDgg8WHv8YLdp9t',
});
client.on('connect', function () {
    console.log('connected');
    client.subscribe('acscan/allTransaction', function (err){
        if (err){
            console.log(err)
        }
    })
})

export const RECENT_TRAN_UPDATE = 'RECENT_TRAN_UPDATE';
export const RECENT_TRAN_INIT = 'RECENT_TRAN_INIT';


export function updateRecentTrans(trans) {
    return {
        type: RECENT_TRAN_UPDATE,
        payload: trans,
    }
}

export function initRecentTrans() {
    return {
        type: RECENT_TRAN_INIT,
    }
}

export function loadRecentTrans() {
    return (dispatch) => {
        dispatch(initRecentTrans());
        fetch(`${API_ADDR_NEW}/transactions/recent`)
            .then(res => res.json())
            .then((data) => {
                dispatch(updateRecentTrans(data));
            }).then(() => {
            client.on('message', function (topic, message) {
                const trans = JSON.parse(message.toString());
                dispatch(updateRecentTrans(trans));
            })
        }).catch(err => {
            console.log(err);
        })
    }
}
