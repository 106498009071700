import { API_ADDR, API_ADDR_NEW } from '../config/config';
import fetch from 'cross-fetch';

export const BLOCK_DEFAULT = 'BLOCK_DEFAULT';
export const BLOCK_LOAD = 'BLOCK_LOAD';

export function defaultBlock() {
	return {
		type: BLOCK_DEFAULT,
	}
}

export function loadBlock(block) {
	return {
		type: BLOCK_LOAD,
		block:block
	}
}



export function loadBlockApi(id) {
	return (dispatch)=> {
		dispatch(defaultBlock());
		fetch(`${API_ADDR_NEW}/blocks/blockNumber?blockNumber=${id}`)
			.then(res => res.json()).then((res) => {
			var _res = {
				block_num:res.num,
				transaction_num: res.transactions,
				block_hash:res.hash,
				// this is hex code you can convert to string.
				producer_hash:res.witness_address,
				parent_hash:res.parentHash,
				time:res.timestamp,
				confirm: "UCF",
				size:0
			};
			dispatch(loadBlock(_res));
		}).catch(err => {
			console.log(err);
		})
	}
}


export const BLOCK_TRANSACTION_DEFAULT = 'BLOCK_TRANSACTION_DEFAULT';
export const BLOCK_TRANSACTION_LOAD = 'BLOCK_TRANSACTION_LOAD';


export function defaultBlockTransaction() {
	return {
		type: BLOCK_TRANSACTION_DEFAULT,
	}
}

export function loadBlockTransaction(transacs) {
	return {
		type: BLOCK_TRANSACTION_LOAD,
		transacs:transacs
	}
}

export function loadBlockTransactionApi(id) {
	return (dispatch)=> {
		dispatch(recentBlock());
		fetch(`${API_ADDR}/blocks/${id}/transactions`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
			mode: 'cors',
		}).then(res => res.json()).then((res) => {
			dispatch(loadBlockTransaction(res.data));
		}).catch(err => {
			console.log(err);
		})
	}
}

export const BLOCK_RECENT = 'BLOCK_RECENT ';
export const BLOCK_RECENT_LOAD = 'BLOCK_RECENT_LOAD';

export function recentBlock() {
	return {
		type: BLOCK_RECENT,
	}
}

export function loadRecentBlock(block) {
	return {
		type: BLOCK_RECENT_LOAD,
		block:block
	}
}

export function loadRecentBlockApi() {
	return (dispatch)=> {
		dispatch(defaultBlock());
		fetch(`${API_ADDR_NEW}/blocks/recent`)
			.then(res => res.json()).then((res) => {

			dispatch(loadBlock(res));
		}).catch(err => {
			console.log(err);
		})
	}
}


