import React from "react";
import { List } from "antd";
import { BlockOutlined } from "@ant-design/icons";
import PerfectScrollbar from "react-perfect-scrollbar";
import { connect } from "react-redux";
import { loadRecentBlocks } from "../../actions/home";
import { Row, Col } from "antd";
import {
  RecentListTitleFrame,
  RecentListTitle,
  RecentListContentFrame,
  RecentItem,
  RecentItemData,
  StyledLink,
  RecentRightCol,
} from "./recent-list";
import { toTimeAgo } from "../../utils/utils";
import { loadWitnesses } from '../../actions/witnesses';
import { FULL_HOST } from "../../config/config"
const TronWeb = require('tronweb')
const tronWeb = new TronWeb({
  fullHost: FULL_HOST,
});

class BlockList extends React.Component {
  componentDidMount() {
    this.props.loadRecentBlocks();
  }
  blockItem = (block) => {
    const witnesses = this.props.witnesses
    // console.log(witnesses)
    const witnessesAddress = tronWeb.address.fromHex(block.witness_address)
    return (
      <List.Item key={block.num}>
        <RecentItem>
          <Row>
            <Col xs={24} sm={24} md={12}>
              <span>
                Block:
                <RecentItemData>
                  <StyledLink to={`/block/${block.num}`}>
                    {block.num}
                  </StyledLink>
                </RecentItemData>
              </span>
            </Col>
            <RecentRightCol xs={24} sm={24} md={12}>
              <span>
                Producer:
                <RecentItemData>
                  {(() => {
                    for (var i = 0; i < witnesses?.length; i++) {
                      if (witnesses[i].address === block.witness_address) {
                       return <StyledLink to={`/account/${witnessesAddress}`}>
                          {witnesses[i].address === block.witness_address
                            ? witnesses[i].url.substring(8)
                            : witnessesAddress}
                        </StyledLink>
                      }
                    }
                  })()}
                </RecentItemData>
              </span>
            </RecentRightCol>
          </Row>
          <Row>
            <Col span={24}>
              <span>
                Include: <RecentItemData>{block.num_of_txs}</RecentItemData>{" "}
                transactions
              </span>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={12}>
              <span>
                Block reward:<RecentItemData>{16}</RecentItemData>
              </span>
            </Col>
            <RecentRightCol xs={24} sm={24} md={12}>
              <span>
                <RecentItemData>
                  {block.timestamp ? toTimeAgo(block.timestamp) : "unknown"}
                </RecentItemData>
              </span>
            </RecentRightCol>
          </Row>
        </RecentItem>
      </List.Item>
    );
  };
  render() {
    let blocks = this.props.blocks ?? [];
    blocks.sort(function (a, b) {
      return b.num - a.num;
    });
    return (
      <div>
        <RecentListTitleFrame>
          <BlockOutlined />
          <RecentListTitle>Recent Blocks</RecentListTitle>
        </RecentListTitleFrame>
        <RecentListContentFrame>
          <PerfectScrollbar>
            <List
              dataSource={blocks}
              loading={blocks.length === 0 ? true : false}
              locale={{ emptyText: "Loading" }}
              renderItem={(block) => this.blockItem(block)}
            />
          </PerfectScrollbar>
        </RecentListContentFrame>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    blocks: state.homeBlocks.blocks,
    witnesses: state.witnesses.witnesses,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loadRecentBlocks: () => {
      dispatch(loadRecentBlocks());
      dispatch(loadWitnesses());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(BlockList);
