import {
    TOKEN_DETAIL_INIT,
    TOKEN_DETAIL_UPDATE,
    TOKEN_TRANSFER_INIT,
    TOKEN_TRANSFER_UPDATE,
    TAB_TRANSFER_INIT,
    TAB_TRANSFER_UPDATE,
    TAB_TRANSFER_TOTAL_UPDATE,
    TAB_TRANSFER_LIMIT_UPDATE, TOKEN_ABI_INIT, TOKEN_ABI_UPDATE,
} from '../actions/token';


const initToken = {
    id: "",
    owner_address: "",
    name: "",
    abbr: "",
    description: "",
    total_supply: 0,
    total_assets: 0,
    frozen_supply: 0,
    trx_num: 0,
    precision: 0,
    num: 0,
    start_time: 0,
    end_time: 0,
    order: 0,
    vote_scroll: 0,
    url: "",
    free_asset_net_limit: 0,
    public_free_asset_net_limit: 0,
}


export function tokenReducer(state = initToken, action) {
    switch (action.type) {
        case TOKEN_DETAIL_UPDATE:
            return action.payload;
        case TOKEN_DETAIL_INIT:
            return initToken;
        default:
            return state;
    }
}

let initTokenTransfer = {

    transferPage: {
        start_page: 1,
        start_item: 0,
        page_limit: 10,
        total_items: 100000,
        current_page: 1
    },
    transfer: [],

}
export function tokenTransferReducer(state = initTokenTransfer, action) {
    switch (action.type) {

        case TOKEN_TRANSFER_UPDATE:
            state = {...state, transfer: action.payload};
            break;
        case TOKEN_TRANSFER_INIT:
            break;
        case TAB_TRANSFER_UPDATE:
            state.transferPage.current_page = action.payload;
            state.transferPage.start_item += (state.transferPage.current_page-state.transferPage.start_page)*state.transferPage.page_limit;
            state.transferPage.start_page = action.payload;
            break;
        case TAB_TRANSFER_INIT:
            break;
        case TAB_TRANSFER_TOTAL_UPDATE:
            state.transferPage.total_items = action.payload;
            break;
        case TAB_TRANSFER_LIMIT_UPDATE:
            state.transferPage.page_limit = action.payload;
            state.transferPage.start_item = (state.transferPage.current_page-1)*state.transferPage.page_limit;
            break;
        default:
            break;

    }
    return state;

}

let initTokenAbi = {
}

export function tokenAbiReducer(state = initTokenAbi, action) {
    switch (action.type) {
        case TOKEN_ABI_UPDATE:
            state = {...state, tokenAbi: action.payload};
            break;
        case TOKEN_ABI_INIT:
            break;
        default:
            break;
    }
    return state;
}


