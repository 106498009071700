import React, { Component } from "react";
import { connect } from "react-redux";
import styled from 'styled-components';
import { CopyOutlined } from '@ant-design/icons';
import { loadAccountDetails } from '../../actions/account';
import { Skeleton} from 'antd';
import { toDateTime, currencyFormat, decimalFormat } from '../../utils/utils';
import {newDetail} from '../../actions/account';
import { FULL_HOST,API_ADDR_NEW } from "../../config/config"
const TronWeb = require('tronweb')
const tronWeb = new TronWeb({
  fullHost: FULL_HOST,
});
const Wrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;
const CardContent = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    flex-direction: row;
    border-bottom: 1px solid #EEEEEE;
    padding-top: 10px;
    padding-bottom: 10px;
`;
const Content = styled.div`
    padding-left: 25px;
    padding-right: 25px;
    background-color: #ffffff;
    width: 100%;
`;
const CardHeader = styled.div`
    text-align: left;
    min-width: 140px;
    font-weight: 600;
`;
const RowTitle = styled.div`
  min-width: 140px;
  text-align: left;
`;
const RowValue = styled.div`
  text-align: left;
  overflow: auto;
`;
const RedText = styled.span`
    color: #E50915;
    `;
class Card extends Component {
  constructor(props) {
    super(props);
    this.state = {data:[], listContract:[]}
  }
  async componentDidMount(){
    this.props.loadAccountDetails(this.props.addr);
    const b =this.props.addr
    const response = await fetch(`${API_ADDR_NEW}/account?address=${b}`);
    const list = await response.json();
    this.setState({ data: list })
    this.setState({ listContract: list.contractList})
  }
  render() {
    const {listContract} = this.state
    const { data}= this.state;
    const content = [
      { title: 'Id', value: <RedText>{data._id ? data._id : "-"}</RedText> },
      { title: 'Account Name', value: <RedText>{data.account_name ? data.account_name : "-"}</RedText> },
      { title: 'Address', value: <RedText>{data.address?tronWeb.address.fromHex(data.address): "-"}</RedText> },
      { title: 'Balance', value: <RedText>{data.balanceACG?currencyFormat(decimalFormat(data.balanceACG/Math.pow(10,6))): "-"} {listContract[1]?listContract[1]:null}</RedText> },
      { title: 'Transactions', value: <RedText>{data.transactions?data.transactions: "-"}</RedText> },
      { title: 'Transfer', value: <RedText>{data.transfer?data.transfer: "-"}</RedText> },
      { title: 'V', value: <RedText>{data.__v?data.__v: "-"}</RedText> },
    ];
    return (
      <Wrap>
        <Skeleton  loading={data.address===""}>
        <Content>
          <CardContent>
            <CardHeader>Address: <span>{tronWeb.address.fromHex(data.address)} <CopyOutlined /> </span></CardHeader>
          </CardContent>
          {content.map((item,index) => {
            return (
              <CardContent key ={index}>
                <RowTitle >{item.title}:</RowTitle>
                <RowValue>{item.value}</RowValue>
              </CardContent>
            )
          })}
        </Content>
        </Skeleton>
      </Wrap>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    account: state.account,
  };
};
const mapDispatchToProps = dispatch => {
	return {
		loadAccountDetails: (addr) => {
			dispatch(loadAccountDetails(addr));
		},
	};
};
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Card);
