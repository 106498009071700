export const API_ADDR = process.env.REACT_APP_API_ADDR || 'https://acscan.io/api/v1';
export const WS_ADDR = process.env.REACT_APP_WS_ADDR || 'wss://acscan.io/ws/api/v1';

// export const API_ADDR = process.env.REACT_APP_API_ADDR || 'http://localhost:8080';
// export const WS_ADDR = process.env.REACT_APP_WS_ADDR || 'localhost:8080';

export const API_ADDR_NEW = process.env.REACT_APP_API_NEW_ADDR || 'https://api.acscan.net/api';
export const WS_ADDR_NEW = process.env.REACT_APP_WS_NEW_ADDR || 'wss://mqtt.acscan.net:8084/mqtt';
export const WSS_ADDR_NEW = process.env.REACT_APP_WSS_NEW_ADDR || 'wss://mqtt.acscan.net:8084/mqtt';
export const FULL_HOST = process.env.TRON_WEB_FULL_HOST || 'https://api.acscan.net';
